import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

import Accordion from 'react-bootstrap/Accordion'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import ListGroup from 'react-bootstrap/ListGroup'

import { ArrowClockwise } from 'react-bootstrap-icons'

import type { GasRecovery } from '../../../api-rec/src/db/gasRecoveries'
import type { EquipmentBorrow } from '../../../api-rec/src/db/equipmentBorrows'
import type { Shipment as ShipmentT } from '../../../api-rec/src/db/shipments'

import { useSearchString } from '../lib/searchState'
import { useOpsTodo, UseOpsTodoT } from 'client/src/queries'

import { TaskCards } from './OpsTaskCards'
import { Recovery, Borrow, Shipment } from './EventCards'
import DateStr from './DateStr'

type OpsTaskType = keyof UseOpsTodoT['tasks']
type OpsTask = UseOpsTodoT['tasks'][OpsTaskType][number]

function Task({ task }: { task: OpsTask }) {
  const { t } = useTranslation()

  const taskDetails = (
    <Accordion.Item eventKey="0">
      <Accordion.Header>{ t('Task Details') }</Accordion.Header>
      <Accordion.Body>
        <ListGroup id={ `task-${task.id}` }>
          <ListGroup.Item>
            <strong>{ t('Task Type') }</strong> { task.type }
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>{ t('Created') }</strong> <DateStr date={ task.createdAt } />
          </ListGroup.Item>
          { task.equipment ? (
            <ListGroup.Item>
              <strong>{ t('Equipment') }</strong>
              <Link to={ `/equipment/${task.equipment.stickerCode}` }>
                ${ task.equipment.stickerCode }
              </Link>
            </ListGroup.Item>
          ) : null }
          { (task.event && 'eqStickerCode' in task.event && task.event.eqStickerCode) ? (
            <ListGroup.Item>
              <strong>{ t('Equipment') }</strong>
              { ' ' }
              <Link to={ `/equipment/${task.event.eqStickerCode}?focus=${task.event.id}` }>
                { task.event.eqStickerCode }
              </Link>
            </ListGroup.Item>
          ) : null }
          { (task.event?.id && task.type != 'validateTechnician') ? (
            <ListGroup.Item>
              <strong>{ t('Event Link') }</strong>
              { ' ' }
              <Link to={ `/events/${task.event.id}` }>Event link</Link>
            </ListGroup.Item>
          ) : null }
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  )

  let eventCard: ReactNode = null
  let eventName: string
  if (task.event) {
    if (task.eventType === 'recovery') {
      const recovery = task.event as GasRecovery
      eventCard = <Recovery event={ recovery } />
      eventName = 'Recovery'
    }
    if (task.eventType === 'borrow') {
      const borrow = task.event as EquipmentBorrow
      eventCard = <Borrow event={ borrow } />
      eventName = 'Borrow'
    }
    if (task.eventType === 'shipment') {
      const shipment = task.event as ShipmentT
      eventCard = <Shipment event={ shipment} />
      eventName = 'Shipment'
    }
  }

  const eventDetails = eventCard ? (
    <Accordion.Item eventKey="1">
      <Accordion.Header>{ t(`${eventName} Details`) }</Accordion.Header>
      <Accordion.Body>
        { eventCard }
      </Accordion.Body>
    </Accordion.Item>
  ) : null

  return (
    <div className="row border border-info rounded my-1 p-1">
      <Accordion className="col-12 col-md-4 col-lg-3" flush>
        {taskDetails}
        {eventDetails}
      </Accordion>

      <TaskCards task={ task } />
    </div>
  )
}

function TaskList({ tasks, loading }: { tasks: OpsTask[], loading: boolean }) {
  if (loading) {
    return (
      <Spinner animation='grow' variant='primary' className="ms-2" />
    )
  }

  return (
    <div>
      { tasks.map(task => (
        <Task key={task.id} task={task} />
      ))}
    </div>
  )
}

function Ops() {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useSearchString('validateRecovery', 'activeTab')
  const { isLoading, isError, data, refetch } = useOpsTodo()

  const loadingSpinner = isLoading ?
    <Spinner animation='grow' size='sm' variant='primary' className="ms-2" /> : null

  const allTasks: Partial<UseOpsTodoT['tasks']> = loadingSpinner || isError ? {} : data.tasks

  const taskTypes: OpsTaskType[] = ['validateRecovery', 'vacuumEquipment', 'validateTechnician', 'validateBorrow', 'validateShipment' ]
  const badges = Object.fromEntries(taskTypes.map((type) => {
      if (loadingSpinner)
        return [type, loadingSpinner]

      const tasks = allTasks[type] || []
      return [
        type,
        <Badge className="ms-2" bg="primary" pill>{ tasks.length }</Badge>,
      ]
    })
  )
  const tasks = (allTasks[activeTab as OpsTaskType] || [])

  return (
    <Tab.Container activeKey={ activeTab } onSelect={ (tab) => setActiveTab(tab) }>
      <Nav variant="tabs">
        <Nav.Item className="d-inline-flex align-items-center me-1">
          <Button size="sm" variant="outline-secondary" onClick={ () => { void refetch() } }>
            <ArrowClockwise />
          </Button>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="validateRecovery">
            { t('Recoveries') }
            { badges['validateRecovery'] }
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="vacuumEquipment">
            { t('Vacuum') }
            { badges['vacuumEquipment'] }
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="validateTechnician">
            { t('New Users') }
            { badges['validateTechnician'] }
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="validateBorrow">
            { t('Equipment borrows') }
            { badges['validateBorrow'] }
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="validateShipment">
            { t('Moves') }
            { badges['validateShipment'] }
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Tab.Content>
        <TaskList tasks={ tasks } loading={ isLoading } />
      </Tab.Content>
    </Tab.Container>
  )
}

export default Ops
