
import { omit } from 'lodash-es'

// https://github.com/ericgio/react-bootstrap-typeahead/blob/HEAD/docs/API.md
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'

import { UseEquipmentT } from 'client/src/queries'

type EquipmentType = Required<UseEquipmentT['equipment'][number]['seriesType']>
type EquipmentTypePickerP = Omit<
  Parameters<typeof Typeahead>[0],
  'options' | 'selected' | 'onChange'
> & {
  selected: Array<EquipmentType>,
  onChange: (equipmentTypes: Array<EquipmentType>) => void | Promise<void>
  equipmentTypes: Array<EquipmentType>
}

function EquipmentTypePicker(props: EquipmentTypePickerP) {
  const equipmentTypes = ([...new Set(props.equipmentTypes)]).sort() || []

  const taProps = omit(props, ['selected', 'onChange'])
  const selected = props.selected
  const onChange = props.onChange

  return (
    <Typeahead
      {...taProps}
      options={ equipmentTypes }
      selected={ selected }
      // @ts-expect-error our option type is Location here
      onChange={ onChange }
    />
  )
}

export default EquipmentTypePicker
