import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'

import { FileX, BoxArrowUpRight } from 'react-bootstrap-icons';

import { useEventImage } from 'client/src/queries'

type EventImageProps = {
  id: string | null,
  label: string,
}

function EventImage({ id, label }: EventImageProps) {
  const { t } = useTranslation()

  const useId = id ? (id.includes('http') ? null : id) : null

  const [thumb, setThumb] = useState(true)
  const { isLoading, isError, data, error } = useEventImage(useId)

  if (!useId) {
    return (
      <em>{ t(`${label} not defined`) }</em>
    )
  }

  if (isLoading) {
    return <Spinner animation='border' size='sm' variant='secondary' />
  }

  if (isError) {
    const header = error instanceof Error ? error.message : String(error)

    return (
      <Card style={ { width: '200px' } }>
        <Card.Header>{ t(header) }</Card.Header>
        <Card.Body><FileX /></Card.Body>
      </Card>
    )
  }

  if (data.contentType === 'image/heif' ) {
    return (
      <span>HEIF file cannot be displayed, please <a href={ data.url } target="_blank">download</a> it instead.</span>
    )
  }


  if (data.contentType === 'application/pdf') {
    return (
      <a href={ data.url } target="_blank">{ t(`View ${label} PDF`) }</a>
    )
  }

  return (
    <Card
      style={ { width: thumb ? '200px' : '100%', cursor: 'pointer' } }
      onClick={ () => setThumb(!thumb) }
    >
      <Card.Img src={data.url} alt={ t(label) } />
      <Card.ImgOverlay>
        <Card.Title className="bg-dark text-white bg-opacity-25">
          { t(label) } <a href={ data.url } target="_blank"><BoxArrowUpRight /></a>
          </Card.Title>
      </Card.ImgOverlay>
    </Card>
  )
}

export default EventImage
