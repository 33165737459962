import Image from 'react-bootstrap/Image'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation } from 'react-i18next';

function ExamplePhoto({src} : {src: string}) {
  const { t } = useTranslation()
  return (
    <Accordion>
    <Accordion.Item eventKey='photoExample' className="mb-3">
      <Accordion.Header>{ t('See example photo') }</Accordion.Header>
        <Accordion.Body><Image className="mx-auto" src={ src } fluid />
        </Accordion.Body>
    </Accordion.Item>
  </Accordion>
  )
}

export default ExamplePhoto
