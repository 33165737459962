import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useForm, Controller } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import { useSearchString } from '../lib/searchState'

import { useUpdateEventWithPhotos } from 'client/src/queries'

import { validImageFileVld, PhotoInputField } from './FormFields'
import CreateProgressModal from './CreateProgressModal'
import EquipmentPicker from './EquipmentPicker'

const CreateQcVld = z.object({
  qcAt: z.date(),
  equipmentId: z.string().min(5, 'Equipment code is required'),
  status: z.enum(['pass', 'fail', 'caution']),
  notes: z.string(),
  qcPhoto: validImageFileVld,
}).refine((qc) => qc.status === 'fail' ? qc.notes.length > 10 : true, {
  message: 'Describe the reason for QC failure',
  path: ['notes'],
})

type CreateQcT = z.infer<typeof CreateQcVld>

function QcForm() {
  const { t } = useTranslation()

  const [ equipmentId, _setEquipmentId ] = useSearchString('', 'eqId')

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos()

  const {
    register, control, handleSubmit, setValue, formState: { errors }, reset: resetForm,
  } = useForm<CreateQcT>({
    resolver: zodResolver(CreateQcVld),
  })

  useEffect(() => {
    if (equipmentId) {
      setValue('equipmentId', equipmentId, { shouldTouch: true })
    }
  }, [equipmentId, setValue])

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} />
    <Row><Col className="mx-auto" md="8" lg="6">
      <h2>{ t('QC') }</h2>

      <Form onSubmit={ handleSubmit((data) => mutate.mutate({ event: data, type: 'qc' })) }>
        <Form.Group controlId="qcDate" className="mb-3">
          <Form.Label>{ t('Date the QC was performed') }</Form.Label>
          <Form.Control
            type="date"
            max={ (new Date()).toISOString().split('T')[0] }
            {...register('qcAt', { valueAsDate: true })}
            isInvalid={ 'qcAt' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.qcAt?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="equipmentId">
            { t('Equipment Code') }
          </Form.Label>
          <Controller
            control={ control }
            name="equipmentId"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => (<>
                < EquipmentPicker
                  id="equipmentId"
                  placeholder={ t('Select equipment code...') }
                  clearButton={ true }
                  selected={ value ? [value] : [] }
                  onChange={ (ids: string[]) => onChange(ids[0]) }
                  onBlur={ onBlur }
                  isInvalid={ invalid }
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>)
            }
          />
        </Form.Group>

        <Form.Group controlId="status" className="mb-3">
          <Form.Label>{ t('QC Status') }</Form.Label>
          <br />
          <Form.Check
            {...register('status')}
            type="radio"
            inline
            label={ t('pass') }
            value="pass"
            isInvalid={ 'status' in errors }
          />

          <Form.Check
            {...register('status')}
            type="radio"
            inline
            label={ t('caution') }
            value="caution"
            isInvalid={ 'status' in errors }
          />

          <Form.Check
            {...register('status')}
            type="radio"
            inline
            label={ t('fail') }
            value="fail"
            isInvalid={ 'status' in errors }
          />
          <Form.Control.Feedback type="invalid" style={ { display: 'status' in errors ? 'block' : 'none' } }>
            { t(errors.status?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="notes" className="mb-3">
          <Form.Label>
            { t('Additional Note') }
            <br />
            <small>{ t('If QC fails, describe why') }</small>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter notes') }
            {...register('notes')}
            isInvalid={ 'notes' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.notes?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="qcPhoto" className="mb-3">
          <Form.Label>{ t('Supporting Photo or Video') }</Form.Label>
          <PhotoInputField control={ control } name="qcPhoto" />
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

export default QcForm;
