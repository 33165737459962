import { useEventsForUser } from 'client/src/queries'
import type { UseEventsT } from 'client/src/queries'
import Spinner from 'react-bootstrap/Spinner'
import Accordion from 'react-bootstrap/Accordion'
import Row from 'react-bootstrap/Row'
import ListGroup from 'react-bootstrap/ListGroup'
import Card from 'react-bootstrap/Card'
import DateStr from './DateStr'
import { CheckCircle, QuestionCircle, XCircle } from 'react-bootstrap-icons'

type Event = UseEventsT['events'][number]
type ExtractEvent<T extends Event, K> = T extends { type: K } ? T : never;
type Recovery = ExtractEvent<Event, 'recovery'>;
// type Recovery = Event extends {type: 'recovery'} ? Event : never

function RecoveryCard(recovery: Recovery) {
  const event = recovery.event
  let validity
  let validityIcon
  if (event.canSell) {
    validity = 'Valid'
    validityIcon = <CheckCircle/>
  } else if (event.canSellAt) {
    validity = 'Invalid'
    validityIcon = <XCircle/>
  } else {
    validity = 'Awaiting Validation'
    validityIcon = <QuestionCircle/>
  }

  const weightDelta = (event.weightBefore && event.weightAfter) ?
    event.weightAfter - event.weightBefore : null
  const weightDisplay = weightDelta ?
    (<span>{weightDelta.toFixed(2)} kg</span>) : 'Unconfirmed'


  return (
  <Card className='px-0'>
      <ListGroup>
       <ListGroup.Item>{event.locationName}: <DateStr date={event.recoveryAt} format='ll' /></ListGroup.Item>
       <ListGroup.Item style={{display: 'flex', alignItems: 'center'}}><span className="inline-icon">{validityIcon}</span>{validity}</ListGroup.Item>
       <ListGroup.Item>Weight: {weightDisplay}</ListGroup.Item>
       <ListGroup.Item>{event.reportedGas.toUpperCase()}</ListGroup.Item>
      </ListGroup>
  </Card>
  )
}

function UserRecoveries() {
  const { isLoading, isError, data, error } = useEventsForUser('recovery')

  let body
  if (isLoading) {
    body = <Spinner animation="border" variant="primary" />
  } else if (isError) {
    body = (<div>{ error instanceof Error ? error.message : String(error) }</div>)
  } else if (data.events.length > 0) {
    body = (<>
      <Accordion className="my-3 highlight-border">
        <Accordion.Header>My Recoveries ({data.events.length})</Accordion.Header>
        <Accordion.Body>
        {data.events.map((r) => <Row key={r.event.id} className='my-2'>{RecoveryCard(r as Recovery)}</Row>)}
        </Accordion.Body>
      </Accordion>
    </>)
  }



  return (<Row className='mx-auto-mb-5'>
    {body}
  </Row>)
}

export { UserRecoveries }
