import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

// docs:
// https://github.com/ericgio/react-bootstrap-typeahead/blob/HEAD/docs/API.md
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'

import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'

import { useSearchArray, useSearchString, useSearchFlag } from '../lib/searchState'
import { useEvents, UseEventsT } from 'client/src/queries'

import EquipmentPicker from './EquipmentPicker'
import DateStr from './DateStr';

function EventDesc({ event }: { event: UseEventsT['events'][number] }) {
  const focus = `?focus=${event.event.id}`

  if (event.type === 'transfer') {
    const tr = event.event

    const [ originB, originA, destB, destA ] = [
      Number(tr.originWeightBefore),
      Number(tr.originWeightAfter),
      Number(tr.destWeightBefore),
      Number(tr.destWeightAfter),
    ]

    const weightOut = (originB && originA) ? `${(originB - originA).toFixed(2)}kg` : null
    const weightIn = (destB && destA ) ? `${(destA - destB).toFixed(2)}kg` : null

    const linkOut = tr.originStickerCode ? <>
      <Link to={ `/equipment/${tr.originStickerCode}${focus}` } target="_blank">{ tr.originStickerCode }</Link>
    </> : 'unknown'

    const linkIn = tr.destStickerCode ? <>
      <Link to={ `/equipment/${tr.destStickerCode}${focus}` } target="_blank">{ tr.destStickerCode }</Link>
    </> : 'unknown'

    return (
      <>
        { tr.technicianName } transferred {linkOut} {weightOut} → {linkIn} {weightIn}
      </>
    )
  } else if (event.type === 'return') {
    return <>{ event.event.borrowerName } returned</>
  } else if (event.type === 'qc') {
    return <>{ event.event.userName } QCed as { event.event.status }</>
  } else if (event.type === 'vacuum') {
    return <>{ event.event.userName } vacuumed to { event.event.pressure } ({ event.event.condition })</>
  } else if (event.type === 'move') {
    const mv = event.event
    return (<>
      { mv.userName } moved { mv.originName || 'unknown' } ({ mv.originPerson }) → { mv.destName } ({ mv.destPerson })
    </>)
  } else if (event.type === 'weight') {
    const w = event.event
    const scale = w.scaleStickerCode ? <>
      using <Link to={ `/equipment/${w.scaleStickerCode}` } target="_blank">{ w.scaleStickerCode }</Link>
    </>: null

    return (<>
      { w.userName } weighed at { w.weight.toFixed(2) }kg { scale }
    </>)
  } else if (event.type === 'borrow') {
    return <>{ event.event.borrowerName } borrowed</>
  } else if (event.type === 'recovery') {
    const rec = event.event
    const recoverWeight = (rec.weightBefore && rec.weightAfter) ? <>
      {(rec.weightAfter - rec.weightBefore).toFixed(2)}kg
    </>: null

    return <>
      {rec.technicianName} recovered {recoverWeight} of {rec.reportedGas} at {rec.locationName} because {rec.recoveryReason}
    </>
  } else if (event.type === 'destruction') {
    return <>Gas destroyed</>
  } else if (event.type === 'created') {
    return <>Equipment entry created</>
  } else if (event.type === 'ident') {
    return <>{ event.event.userName } IDed as { event.event.pctR134 }% R134, { event.event.pctR12 }% R12, { event.event.pctR22 }% R22, { event.event.pctHc }% HC, { event.event.pctAir }% air.</>
  }
}

function EventsTable({ events }: { events: UseEventsT['events'] }) {
  const { t } = useTranslation()

  events.forEach(evt => { evt.ts = new Date(evt.ts) })

  const rows = events.sort((a, b) => b.ts.valueOf() - a.ts.valueOf()).map(evt => {
    const eqCode = evt.type === 'transfer' ? evt.event.originStickerCode : ('eqStickerCode' in evt.event ? evt.event.eqStickerCode : '' )
    const eqLink = eqCode ? (
      <Link to={ `/equipment/${eqCode}?focus=${evt.event.id}` } target="_blank">
        { eqCode }
      </Link>
    ) : null
    const isDeleted = ('deletedAt' in evt.event) && (evt.event.deletedAt)

    return (
      <tr key={ `${evt.type}-${evt.event.id}` }>
        <td style={ { whiteSpace: 'nowrap' } }>
          <Link to={ `/events/${evt.event.id}` }>
            {isDeleted
              ? <del> <DateStr date={ evt.ts } /> </del>
              : <DateStr date={ evt.ts } />
            }
          </Link>
          </td>
        <td> {isDeleted ? <del>{evt.type}</del> : evt.type } </td>
        <td> {isDeleted ? <del>{eqLink}</del> : eqLink } </td>
        <td> {isDeleted ? <del><EventDesc event={evt} /></del> : <EventDesc event={evt} /> } </td>
      </tr>
    )
  })

  return (
    <Table size="sm" striped={true}>
      <thead>
        <tr>
          <th>{ t('Date') }</th>
          <th>{ t('Type') }</th>
          <th>{ t('Equipment') }</th>
          <th>{ t('Description') }</th>
        </tr>
      </thead>

      <tbody>
        { rows }
      </tbody>
    </Table>
  )
}

const EventTypes = [
  'qc', 'vacuum', 'move', 'weight', 'borrow', 'return', 'recovery', 'transfer', 'destruction', 'created', 'ident',
]

function Events() {
  const { t } = useTranslation()

  const [eqs, setEqs] = useSearchArray([], 'forEq')

  const [minDate, setMinDate] = useSearchString('', 'minDate')
  const [maxDate, setMaxDate] = useSearchString('', 'maxDate')

  const [evtTypes, setEvtTypes] = useSearchArray([], 'evtTypes')

  const [includeDeleted, setIncludeDeleted] = useSearchFlag('includeDeleted')

  const today = new Date().toISOString().split('T')[0]

  const { isLoading, isError, data, error } = useEvents(eqs, minDate, maxDate, evtTypes, includeDeleted)

  const body = isLoading ? (<Spinner animation="border" variant="primary" />)
    : (
      isError ? (<div>{ error instanceof Error ? error.message : String(error) }</div>)
        : ( <EventsTable events={ data.events } /> )
    )

  return (
    <>
      <Row className="mb-1">
        <Form.Group as={ Col } xs={ 12 } md={ 4 }>
          <Form.Label htmlFor="equipmentFilter">{ t('For Equipment') }:</Form.Label>
          <EquipmentPicker
            multiple
            id="equipmentFilter"
            placeholder={ t('Pick equipment...') }
            clearButton={true}
            selected={ eqs }
            onChange={ setEqs }
          />
        </Form.Group>

        <Form.Group as={ Col } xs={ 6 } md={ 2 } controlId="minDate">
          <Form.Label>{ t('Min Date') }:</Form.Label>
          <Form.Control
            type="date"
            value={ minDate }
            max={ maxDate ? maxDate : today }
            onChange={ (evt) => setMinDate(evt.target.value) }
          />
        </Form.Group>

        <Form.Group as={ Col } xs={ 6 } md={ 2 } controlId="maxDate">
          <Form.Label>{ t('Max Date') }:</Form.Label>
          <Form.Control
            type="date"
            value={ maxDate }
            min={ minDate ? minDate : '' }
            max={ today }
            onChange={ (evt) => setMaxDate(evt.target.value) }
          />
        </Form.Group>

        <Form.Group as={ Col } xs={ 12 } md={ 4 } controlId="evtTypes">
          <Form.Label>{ t('Event Types') }:</Form.Label>
          <Typeahead
            multiple
            id="equipmentFilter"
            clearButton={true}
            placeholder={ t('Event types...') }
            options={ EventTypes }
            selected={ evtTypes }
            // @ts-expect-error our option type is equipment here
            onChange={ (selected: string[]) => setEvtTypes(selected) }
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={ Col } xs={ 12 } md={ 4 } controlId="includeDeleted">
          <Form.Check
            type="checkbox"
            id="includeDeletedCheck"
            label={ t('Include Deleted?') }
            checked={ includeDeleted }
            onChange={ () => setIncludeDeleted(!includeDeleted) }
          />
        </Form.Group>
      </Row>

      { body }
    </>
  )
}

export default Events;
