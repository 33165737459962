import { ReactNode } from 'react'

import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { sumBy } from 'lodash-es'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Spinner from 'react-bootstrap/Spinner'
import Stack from 'react-bootstrap/Stack'

import { useUpdateEventWithPhotos } from 'client/src/queries';

import { addAlert } from '../state/alerts'

type AgainButton = {
  successLabel: string,
  successFn: () => void,
}

type CreateProgressModalP = ReturnType<typeof useUpdateEventWithPhotos> & {
  resetForm: () => void,
  againButton?: AgainButton,
}

function CreateProgressModal({ progress, mutate, reset, resetForm, againButton }: CreateProgressModalP) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!progress.running) {
    return <></>
  }

  const files = Object.values(progress.files)
  const total = 2 * files.length + 2
  const cur = sumBy(files, (file) => file.uploaded ? 2 : (file.registered ? 1 : 0)) + 1

  let now = Math.round((cur / total) * 100)
  let variant = 'primary'
  let animated = true
  let label = t('Creating event...')
  let againLabel: ReactNode = null
  let eventLink: string | null = null

  const error = mutate.error

  if (error) {
    variant = 'danger'
    animated = false
    label = `${t('Error')}: ${error as string}`
    againLabel = t('Try again')
  } else if (progress.done) {
    now = 100
    variant = 'success'
    animated = false
    label = t('Complete')
    againLabel = t(againButton?.successLabel || 'Submit another')

    const event = mutate.data.event
    let eqCode: string | null
    if (event) {
      if ('eqStickerCode' in event) {
        eqCode = event.eqStickerCode
      } else if ('destStickerCode' in event) {
        eqCode = event.destStickerCode
      }
    }

    if (eqCode) {
      eventLink = `/equipment/${eqCode}?focus=${event.id}`
    }

  } else {
    againLabel = <>
      <Spinner animation="border" variant="dark" size="sm" className="me-1" />
      {t('Processing')}
    </>
  }

  const againFn = () => {
    if (error) {
      reset()
    } else if (progress.done) {
      let desc: string
      if (mutate.data.event) {
        desc = `${mutate.data.type} ${mutate.data.event.id}`
      }
      else {
        desc = 'success!'
      }
      const link = eventLink ? <Link to={ eventLink }>{desc}</Link> : desc
      addAlert({ level: 'ok', message: <>You created {link}</> })

      resetForm()
      reset()

      againButton?.successFn()
    }
  }

  return (
    <Modal show={ true } onHide={ againFn }>
      <Modal.Header closeButton={ !!(progress.done || error) }>
        <Modal.Title>
          { t('Processing Event') }
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Stack direction="horizontal" gap={1} className="mb-2">
          { animated ? <Spinner animation="border" variant="primary" /> : null }
          { label }
        </Stack>

        <ProgressBar
          now={now} variant={variant} striped={animated} animated={animated} label={ `${now}%` } />
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={ againFn }
          disabled={ !error && !progress.done }
        >
          { againLabel }
        </Button>

        <Button
          variant="primary"
          onClick={ () => eventLink && navigate(eventLink) }
          hidden={ !eventLink }
        >
          Go to Event
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateProgressModal
