import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useForm, Controller } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import classNames from 'classnames'

import { useSearchString } from '../lib/searchState'

import { useUpdateEventWithPhotos, useEquipmentState } from 'client/src/queries'

import { validImageFileVld, PhotoInputField } from './FormFields'
import CreateProgressModal from './CreateProgressModal'
import EquipmentPicker from './EquipmentPicker'

const CreateTransferVld = z.object({
  transferAt: z.date(),
  isSampling: z.boolean(),
  technicianName: z.string().min(5, 'Technician name is required'),
  originEquipmentId: z.string().min(5, 'Source equipment code is required'),
  destEquipmentId: z.string().min(5, 'Destination equipment code is required'),
  originBeforePhoto: validImageFileVld,
  originAfterPhoto: validImageFileVld,
  // TODO: refine these - weight before < weight after
  originWeightBefore: z.number().positive(),
  originWeightAfter: z.number().positive(),
})

type CreateTransferT = z.infer<typeof CreateTransferVld>

function EquipmentInfo({ equipmentId }: { equipmentId: string | null }) {
  const { t } = useTranslation()

  const { data, isLoading } = useEquipmentState(equipmentId)

  if (isLoading) {
    return <div>{ t('Loading...') }</div>
  }

  if (!data || data.equipment.equipmentType !== 'cylinder' ) {
    return null;
  }

  return <ul className="list-group">
    <li className="list-group-item"><b>{ t('Capacity') }</b>: { data.equipment.seriesCapacity } L</li>
    <li className="list-group-item"><b>{ t('Current Gas') }</b>: { data.state.gasType }</li>
    <li className="list-group-item"><b>{ t('Current Gas Weight') }</b>: { data.state.gasWeight.remaining/1000 } kg</li>
  </ul>
}

function TransferForm() {
  const { t } = useTranslation()

  const [ equipmentId, _setEquipmentId ] = useSearchString('', 'eqId')

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos()

  const {
    register, control, handleSubmit, setValue, watch, setError, clearErrors, formState: { errors }, reset: resetForm,
  } = useForm<CreateTransferT>({
    resolver: zodResolver(CreateTransferVld),
  })

  useEffect(() => {
    if (equipmentId) {
      setValue('originEquipmentId', equipmentId, { shouldTouch: true })
      setValue('destEquipmentId', equipmentId, { shouldTouch: true })
    }
  }, [equipmentId, setValue])

  // validation on the equipment
  const [originEquipmentId, destEquipmentId] = [watch('originEquipmentId'), watch('destEquipmentId')]
  const { data: originEquipmentState } = useEquipmentState(originEquipmentId)
  const { data: destEquipmentState } = useEquipmentState(destEquipmentId)

  useEffect(() => {
    if (!originEquipmentState && !destEquipmentId) return;

    if (originEquipmentState) {
      if (originEquipmentState.equipment.equipmentType !== 'cylinder') {
        setError('originEquipmentId', { message: 'Source equipment is not a cylinder' })
      }
    }

    if (destEquipmentState) {
      if (destEquipmentState.equipment.equipmentType !== 'cylinder') {
        setError('destEquipmentId', { message: 'Destination equipment is not a cylinder' })
      }
    }

    if (originEquipmentState && destEquipmentState) {
      if (originEquipmentState.equipment.id === destEquipmentState.equipment.id)
        setError('destEquipmentId', { message: 'Source and destination equipment cannot be the same' });

      const [originGas, destGas ] = [originEquipmentState.state.gasType, destEquipmentState.state.gasType]
      if (originGas !== destGas && originGas !== 'unknown' && destGas !== 'unknown') {
        setError('destEquipmentId', { message: `Cannot combine source gas ${originGas} with destination gas ${destGas}` })
      }
    }
  }, [originEquipmentState, destEquipmentState])

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} />
    <Row><Col className="mx-auto" md="8" lg="6">
      <h2>{ t('Transfer') }</h2>

      <Form onSubmit={ handleSubmit((data) => mutate.mutate({ event: data, type: 'transfer' })) }>

        <Form.Group controlId="technicianName" className="mb-3">
          <Form.Label>
            { t('Name of the person performing the transfer') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter name') }
            {...register('technicianName')}
            isInvalid={ 'technicianName' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.technicianName?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="transferDate" className="mb-3">
          <Form.Label>{ t('Date the transfer was performed') }</Form.Label>
          <Form.Control
            type="date"
            max={ (new Date()).toISOString().split('T')[0] }
            {...register('transferAt', { valueAsDate: true })}
            isInvalid={ 'transferAt' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.transferAt?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="isSampling" className="mb-3">
          <Form.Label>{ t('Transfer purpose') }</Form.Label>
          <Form.Check
            type="switch"
            {...register('isSampling')}
            isInvalid={ 'isSampling' in errors }
            label="Is this for the purpose of sampling?"
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.isSampling?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>


        <div className={ classNames('mb-3 p-2', { 'bg-light-subtle': !errors.originEquipmentId, 'bg-danger-subtle': errors.originEquipmentId }) }>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="equipmentId">
              { t('Source Equipment Code') }
            </Form.Label>
            <Controller
              control={ control }
              name="originEquipmentId"
              render={
                (
                  { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
                ) => (<>
                  < EquipmentPicker
                    types={['cylinder']}
                    id="equipmentId"
                    placeholder={ t('Select source equipment code...') }
                    clearButton={ true }
                    selected={ value ? [value] : [] }
                    onChange={ (ids: string[]) => { clearErrors('originEquipmentId'); onChange(ids[0]); } }
                    onBlur={ onBlur }
                    isInvalid={ invalid }
                  />
                  <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                    { t(error?.message || 'Error') }
                  </div>
                </>)
              }
            />
          </Form.Group>

          <EquipmentInfo equipmentId={ originEquipmentId } />
        </div>

        <div className={ classNames('mb-3 p-2', { 'bg-light-subtle': !errors.destEquipmentId, 'bg-danger-subtle': errors.destEquipmentId }) }>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="equipmentId">
              { t('Destination Equipment Code') }
            </Form.Label>
            <Controller
              control={ control }
              name="destEquipmentId"
              render={
                (
                  { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
                ) => (<>
                  < EquipmentPicker
                    types={['cylinder']}
                    id="equipmentId"
                    placeholder={ t('Select destination equipment code...') }
                    clearButton={ true }
                    selected={ value ? [value] : [] }
                    onChange={ (ids: string[]) => { clearErrors('destEquipmentId'); onChange(ids[0]); } }
                    onBlur={ onBlur }
                    isInvalid={ invalid }
                  />
                  <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                    { t(error?.message || 'Error') }
                  </div>
                </>)
              }
            />
          </Form.Group>

          <EquipmentInfo equipmentId={ destEquipmentId } />
        </div>

        <Form.Group controlId="originWeightBefore" className="mb-3">
          <Form.Label>{ t('Weight of the origin cylinder before transfer') }</Form.Label>
          <Form.Control
            {...register('originWeightBefore', { valueAsNumber: true }) }
            type="number"
            step="0.01"
            isInvalid={ 'originWeightBefore' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.originWeightBefore?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="originBeforePhoto" className="mb-3">
          <Form.Label>{ t('Photo of the origin cylinder before transfer') }</Form.Label>
          <PhotoInputField control={ control } name="originBeforePhoto" />
        </Form.Group>

        <Form.Group controlId="originWeightAfter" className="mb-3">
          <Form.Label>{ t('Weight of the origin cylinder after transfer') }</Form.Label>
          <Form.Control
            {...register('originWeightAfter', { valueAsNumber: true }) }
            type="number"
            step="0.01"
            isInvalid={ 'originWeightAfter' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.originWeightAfter?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="originAfterPhoto" className="mb-3">
          <Form.Label>{ t('Photo of the origin cylinder after transfer') }</Form.Label>
          <PhotoInputField control={ control } name="originAfterPhoto" />
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

export default TransferForm;
