/*
 * owner: owned by Recoolit / other?
 * initial location dropdown typeahead
 * equipment series - dropdown typeahead
 * photo
 * notes
 */
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { useForm, Controller } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import {
  useUpdateEventWithPhotos,
  useEquipmentSeries,
} from 'client/src/queries'

import { useSearchString } from '../lib/searchState';
import { validImageFileVld, PhotoInputField } from './FormFields'
import CreateProgressModal from './CreateProgressModal'
import LocationPicker from './LocationPicker';
import StickerPicker from './StickerPicker';
import EquipmentTypePicker from './EquipmentTypePicker'
import ExamplePhoto from './ExamplePhoto';

import borrowExample from '../assets/borrowExample.jpg'

const CreateEquipmentVld = z.object({
  isRecoolitOwned: z.boolean(),
  owner: z.string(),
  stickerCode: z.string().min(5, 'Sticker is required'), // code
  initialLocationId: z.string().min(5, 'Location is required'),
  seriesName: z.string().min(5, 'Equipment Series is required'),
  notes: z.string(),
  photo: validImageFileVld,
}).refine((eq) => eq.isRecoolitOwned || eq.owner.length > 5, {
  message: 'Must input an owner.',
  path: ['owner'],
})

type CreateEquipmentT = z.infer<typeof CreateEquipmentVld>

function NewEquipmentForm() {
  const { t } = useTranslation()

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos()

  const [ stickerCode, _setSticker ] = useSearchString('', 'stickerCode')

  const {
    register, watch, setValue, control, handleSubmit, formState: { errors }, reset: resetForm,
  } = useForm<CreateEquipmentT>({
    resolver: zodResolver(CreateEquipmentVld),
  })

  useEffect(() => {
    if (stickerCode) {
      setValue('stickerCode', stickerCode, { shouldTouch: true })
    }
  }, [stickerCode, setValue])


  const { isLoading: seriesLoading, error: seriesError, data: seriesData } = useEquipmentSeries()
  const isRecoolitOwned = watch('isRecoolitOwned')


  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} />
    <Row><Col className="mx-auto mb-5" md="8" lg="6">
      <h2>{ t('Equipment') }</h2>

      <p>
        { t('Please fill out this form to record new equipment') }
      </p>

      <Form onSubmit={ handleSubmit((data) => mutate.mutate({ event: data, type: 'created' })) }>


      <Form.Group className="mb-3">
        <Form.Label htmlFor="stickerCode">
          { t('Sticker Code') }
        </Form.Label>
        <Controller
          control={ control }
          name="stickerCode"
          render={
            (
              { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
            ) => (<>
              < StickerPicker
                id="stickerCode"
                placeholder={ t('Select sticker code...') }
                clearButton={ true }
                selected={ value ? [value] : [] }
                onChange={ (ids: string[]) => onChange(ids[0]) }
                onBlur={ onBlur }
                isInvalid={ invalid }
                unusedOnly={true}
              />
              <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                { t(error?.message || 'Error') }
              </div>
            </>)
          }
        />
        </Form.Group>
        <Form.Group controlId="isRecoolitOwned" className="mb-3">
          <Form.Label>
            { t('Does Recoolit own this equipment?') }
          </Form.Label>
          <Form.Check
            type="checkbox"
            label="Yes"
            {...register('isRecoolitOwned')}
            isInvalid={ 'isRecoolitOwned' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.isRecoolitOwned?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="owner" className="mb-3">
          <Form.Label>
            { t('If not, who owns it?') }
          </Form.Label>
          <Form.Control
            type="text"
            {...register('owner')}
            isInvalid={ 'owner' in errors }
            disabled={isRecoolitOwned}
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.owner?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="initialLocationId">
            { t('Location where the equipment was registered') }
          </Form.Label>
          <Controller
            control={ control }
            name="initialLocationId"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => (<>
                <LocationPicker
                  id="initialLocationId"
                  placeholder={ t('Select location...') }
                  clearButton={ true }
                  onBlur={ onBlur }
                  isInvalid={ invalid }
                  selected={ value ? [value] : [] }
                  onChange={ (selected: string[]) => onChange(selected[0]) }
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>)
            }
          />
        </Form.Group>

       <Form.Group className="mb-3">
          <Form.Label htmlFor="seriesName">
            { t('Equipment Series') }
          </Form.Label>
          <Controller
            control={ control }
            name="seriesName"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => {
                if (seriesLoading) { return <span>('Loading...')</span> }
                else if (seriesError) { return <span>('Error...')</span> }
                else { return (<>
                <EquipmentTypePicker
                  id="seriesName"
                  placeholder={ t('Select...') }
                  equipmentTypes={ seriesData.series.map((s) => s.name) || [] }
                  clearButton={ true }
                  onBlur={ onBlur }
                  isInvalid={ invalid }
                  selected={ value ? [value] : [] }
                  onChange={ (selected: string[]) => onChange(selected[0]) }
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>) }
            }}
          />
       </Form.Group>

         <Form.Group controlId="notes" className="mb-3">
          <Form.Label>
            { t('Notes') }
            <br />
            <small>{ t('Anything else to record about the equipment?') }</small>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter notes') }
            {...register('notes')}
            isInvalid={ 'notes' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.notes?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="photo" className="mb-3">
          <Form.Label>{ t('Photo of the equipment with sticker') }</Form.Label>
          <ExamplePhoto src={borrowExample}></ExamplePhoto>
          <PhotoInputField control={ control } name="photo" />
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

export default NewEquipmentForm;
