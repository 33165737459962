import { omit } from 'lodash-es'

// https://github.com/ericgio/react-bootstrap-typeahead/blob/HEAD/docs/API.md
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'

import { UseEquipmentStatesT } from 'client/src/queries'

type GasType = Required<UseEquipmentStatesT['states'][number]['cylinderState']['gasType']>
type GasTypePickerP = Omit<
  Parameters<typeof Typeahead>[0],
  'options' | 'selected' | 'onChange'
> & {
  selected: Array<GasType>,
  onChange: (gasTypes: Array<GasType>) => void | Promise<void>
  gasTypes: Array<GasType>
}

function GasTypePicker(props: GasTypePickerP) {
  const gasTypes = ([...new Set(props.gasTypes)]).sort() || []

  const taProps = omit(props, ['selected', 'onChange'])
  const selected = props.selected
  const onChange = props.onChange

  return (
    <Typeahead
      {...taProps}
      options={ gasTypes }
      selected={ selected }
      // @ts-expect-error our option type is gasTypes here
      onChange={ onChange }
    />
  )
}

export default GasTypePicker
