import { BarcodeScanner } from 'react-barcode-scanner'
import type { DetectedBarcode } from 'react-barcode-scanner'
import 'react-barcode-scanner/polyfill'


function QrScanner({onDetect}: {onDetect: (value: string) => void}) {
  const onCapture = (detected: DetectedBarcode) => {
    if (detected) {
      const value = detected.rawValue
      onDetect(value)
    }
  }
  return (
    <BarcodeScanner onCapture={onCapture} />
  )
}
export default QrScanner
