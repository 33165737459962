import { omit, compact } from 'lodash-es'

// https://github.com/ericgio/react-bootstrap-typeahead/blob/HEAD/docs/API.md
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'

import { useEquipment, UseEquipmentT } from 'client/src/queries'

type Equipment = UseEquipmentT['equipment'][number]
type EquipmentPickerP = Omit<
  Parameters<typeof Typeahead>[0],
  'isLoading' | 'options' | 'labelKey' | 'selected' | 'onChange'
> & {
  types?: Array<Exclude<Equipment['equipmentType'], undefined>>,
  selected: Array<Equipment['id']>,
  onChange: (equipmentIds: Array<Equipment['id']>) => void | Promise<void>
}

function EquipmentPicker(props: EquipmentPickerP) {
  const { isLoading: eqLoading, data: eqData } = useEquipment(props.types)
  const equipment = eqData?.equipment || []

  const taProps = omit(props, ['selected', 'onChange'])
  const selected = compact(props.selected.map(eqId => equipment.find(eq => eq.id === eqId)))
  const onChange = props.onChange

  return (
    <Typeahead
      {...taProps}
      isLoading={eqLoading}
      options={ equipment }
      labelKey={ 'stickerCode' }
      selected={ selected }
      // @ts-expect-error our option type is equipment here
      onChange={ (selected: Equipment[]) => onChange(selected.map(eq => eq.id)) }
    />
  )
}

export default EquipmentPicker
