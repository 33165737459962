import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import SETTINGS from './settings'
import enTranslations from '../locale/en.json'
import idTranslations from '../locale/id.json'

async function initI18n (debug = true) {
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  i18n.use(LanguageDetector)

  // pass the i18n instance to react-i18next.
  i18n.use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  await i18n.init({
      fallbackLng: 'en',

      debug: debug && !SETTINGS.isProd,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      resources: {
      },
    });

  i18n.addResourceBundle('en', 'translation', enTranslations)
  i18n.addResourceBundle('id', 'translation', idTranslations)
}

export default initI18n;
