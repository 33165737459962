import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom'

import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import { useEquipment, useEquipmentBorrowed, useEquipmentStates } from 'client/src/queries'
import type { UseEquipmentT, UseEquipmentBorrowedT, UseEquipmentStatesT } from 'client/src/queries';

import DateStr from './DateStr';
import LocationPicker from './LocationPicker'
import GasTypePicker from './GasTypePicker'
import EquipmentTypePicker from './EquipmentTypePicker'
import { useSearchArray, useSearchFlag, useSearchString } from '../lib/searchState';

type Equipment = UseEquipmentT['equipment']
type EqpStates = UseEquipmentStatesT['states']

function formattedGasQuantity (state: EqpStates[number]): string {
  const quantity = state.cylinderState?.gasQuantity
  return quantity === undefined ? '' : quantity.toFixed(2)
}

type EqTableBodyP = {
  equipment: Equipment,
  states: EqpStates,
  borrowed: UseEquipmentBorrowedT['borrowed'] | null,
}

function EqTableBody({ equipment, states, borrowed }: EqTableBodyP) {
  return (
    <tbody>
      { equipment.map(eq => (
        <tr key={eq.id}>
          <td>
            <Link to={`/equipment/${eq.stickerCode}`}>
              {eq.stickerCode}
            </Link>
          </td>
          <td>{states[eq.id].locationName}</td>
          <td>{ borrowed[eq.id] ? borrowed[eq.id].borrowerName : null }</td>
          <td>{ borrowed[eq.id] ? <DateStr date={borrowed[eq.id].borrowAt} /> : null }</td>
          <td>{eq.seriesType}</td>
          <td>{eq.seriesCapacity}</td>
          <td>{states[eq.id].steward}</td>
          <td>{states[eq.id].cylinderState?.gasType}</td>
          <td>{formattedGasQuantity(states[eq.id])}</td>
          <td>{states[eq.id].condition}</td>
          <td>{states[eq.id].cylinderState?.isFreshlyVacuumed.toString()}</td>

        </tr>
      )) }
    </tbody>
  )
}

function EquipmentTable() {
  const { t } = useTranslation()

  const { isLoading: eqLoading, error: eqError, data: eqData } = useEquipment()
  const { isLoading: statesLoading, error: statesError, data: statesData } = useEquipmentStates()
  const { isLoading: borrowLoading, error: borrowError, data: borrowData } = useEquipmentBorrowed()

  const [ showBorrowed, setSearchBorrowed ] = useSearchFlag('borrowed')
  const [ locations, setLocations ] = useSearchArray([], 'locations')
  const [ qcStatus, setQcStatus ] = useSearchString('', 'qc')
  const [ equipmentTypes, setEquipmentTypes ] = useSearchArray([], 'equipmentTypes')
  const [ gasTypes, setGasTypes ] = useSearchArray([], 'gasTypes')

  const missingQcStatus = 'missing'

  const colSpan = 10

  let body: ReactNode = null
  if (eqLoading || statesLoading || borrowLoading) {
    body = <tbody><tr><td colSpan={ colSpan }>Loading...</td></tr></tbody>
  }

  if (eqError || statesError || borrowError) {
    const eqMsg = eqError ? (eqError as Error).message : ''
    const statesMsg = statesError ? ( statesError as Error).message : ''
    const borrowMsg = borrowError ? (borrowError as Error).message : ''

    const error = `Error: ${eqMsg} ${statesMsg} ${borrowMsg}`
    body = <tbody><tr><td colSpan={ colSpan }>{ error }</td></tr></tbody>
  }

  if (!body) {
    let equip = eqData.equipment || []
    if (locations.length) {
      equip = equip.filter(eq => locations.includes(eq.lastDestId))
    }

    if (showBorrowed) {
      equip = equip.filter(eq => borrowData.borrowed[eq.id])
    }

    if (qcStatus) {
      if (qcStatus === missingQcStatus) {
        equip = equip.filter(eq => (statesData.states[eq.id].condition === ''))
      } else {
        equip = equip.filter(eq => (statesData.states[eq.id].condition === qcStatus))
      }
    }
    if (equipmentTypes.length) {
      equip = equip.filter(eq => equipmentTypes.includes(eq.seriesType || ''))
    }
    if (gasTypes.length) {
      equip = equip.filter(eq => gasTypes.includes(statesData.states[eq.id].cylinderState?.gasType) || '')
    }

    body = (
      <EqTableBody
        equipment={ equip }
        states={ statesData.states }
        borrowed={ borrowData.borrowed }
      />
    )
  }

  return (
    <>
      <Row className="mb-1">
        <Col md="6">
          <Form.Group controlId="equipmentTypes">
            <Form.Label>{ t('Equipment Types') }</Form.Label>
            <EquipmentTypePicker
              id="equipmentTypes"
              multiple
              clearButton={true}
              placeholder={ t('Pick types...') }
              equipmentTypes={(eqData) ? eqData.equipment.map(eq => eq.seriesType) : [] }
              selected={ equipmentTypes }
              onChange={ (selected: string[]) => setEquipmentTypes(selected) }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Form.Check
            type="checkbox"
            id="currentlyBorrowedCheck"
            label={ t('Currently Borrowed?') }
            checked={ showBorrowed }
            onChange={ () => setSearchBorrowed(!showBorrowed) }
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Form.Group controlId="locations">
            <Form.Label>{ t('At locations') }</Form.Label>
            <LocationPicker
              id="locations"
              multiple
              clearButton={true}
              placeholder={ t('Pick locations...') }
              selected={ locations }
              onChange={ (selected: string[]) => setLocations(selected) }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md="6">
          <Form.Group controlId="qcStatus">
            <Form.Label>{ t('QC Status') }</Form.Label>
              <Form.Select
                id="qcSelect"
                onChange={ (e) => setQcStatus(e.target.value) }
              >
                <option value="">All</option>
                <option value="pass">Pass</option>
                <option value="fail">Fail</option>
                <option value="caution">Caution</option>
                <option value={missingQcStatus}>(missing status)</option>
              </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md="6">
          <Form.Group controlId="gasTypes">
            <Form.Label>{ t('Gas Types') }</Form.Label>
            <GasTypePicker
              id="gasTypes"
              multiple
              clearButton={true}
              placeholder={ t('Pick types...') }
              gasTypes={
                (statesData)
                  ? Object.keys(statesData.states).map(k => statesData.states[k].cylinderState?.gasType || '')
                 : []
              }
              selected={ gasTypes }
              onChange={ (selected: string[]) => setGasTypes(selected) }
            />
          </Form.Group>
        </Col>
      </Row>

      <Table size="sm" striped={true}>
        <thead>
          <tr>
            <th>{ t('ID') }</th>
            <th>{ t('Location') }</th>
            <th>{ t('Borrower') }</th>
            <th>{ t('Borrowed Since') }</th>
            <th>{ t('Type') }</th>
            <th>{ t('Capacity') }</th>
            <th>{ t('Steward') }</th>
            <th>{ t('Gas Type') }</th>
            <th>{ t('Net Weight') }</th>
            <th>{ t('Condition') }</th>
            <th>{ t('Is Fresh?') }</th>
          </tr>
        </thead>

        { body }
      </Table>
    </>
  )
}

export default EquipmentTable;
