import React from 'react';
import ReactDOM from 'react-dom/client';

// initialize translations by loading them
import initI18n from './lib/i18n'
import { initSentry } from './lib/sentry'

import App from './containers/App'

const rootEl = document.getElementById('root')!
const root = ReactDOM.createRoot(rootEl)

async function main () {
  // set up error reporting
  initSentry()

  // render app only after initializing translations
  await initI18n(false)

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

void main()
