import { PropsWithChildren } from 'react'

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import classNames from 'classnames'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'

import {
  Icon,
  Tornado,
  Check2Circle,
  BoxArrowInDownRight,
  BoxArrowUpRight,
  Fingerprint,
  BootstrapReboot,
  Wrench,
} from 'react-bootstrap-icons'

import { Weight } from './Linktree'

import type { UseEquipmentByStickerT } from 'client/src/queries'

type ActionProps = {
  icon: Icon | null,
  text: string,
  route?: string,
  params?: Record<string, keyof UseEquipmentByStickerT['equipment']>,
}

// TODO prefill date? and user?
const cylinderActions: ActionProps[] = [
  { icon: Tornado, text: 'Vacuum', route: '/forms/vacuum', params: {'eqId':'equipmentId'}},
  {
    icon: Weight,
    text: 'Weigh',
    route: '/forms/weight',
    params: {'eqId': 'equipmentId'},
  },
  {
    icon: BootstrapReboot,
    text: 'Recover Refrigerant',
    route: '/forms/recovery',
  },
  {
    icon: BoxArrowUpRight,
    text: 'Transfer Out',
    route: '/forms/transfer',
    // TODO params: {'eqId': 'equipmentId'},
  },
  {
    icon: BoxArrowInDownRight,
    text: 'Transfer In',
    route: '/forms/transfer',
    // TODO params: {'eqId': 'equipmentId'},
  },
  {
    icon: Fingerprint,
    text: 'Identify Gas',
    route: '/forms/ident',
    // TODO? params: {'eqId': 'equipmentId'},
  },
]

const nonCylinderActions: ActionProps[] = [
  { icon: Check2Circle, text: 'Quality Control', route: '/forms/qc', params: {'eqId': 'equipmentId'}},
]

const returnAction: ActionProps = {
  icon: Wrench, // TODO maybe fix?
  text: 'Return Equipment',
  route: '/forms/return',
  params: {'eqId': 'equipmentId'},
}

type ActionLinkP = PropsWithChildren & {
  action: ActionProps,
  equipment: UseEquipmentByStickerT['equipment'],
}

function ActionLink({ action, equipment, children}: ActionLinkP) {
  const className = classNames('btn btn-primary btn-lg')

  const params = new URLSearchParams()
  for (const [key, eqKey] of Object.entries(action.params || {})) {
    params.set(key, equipment[eqKey] as string)
  }

  if (action.route) {
    const query = params.toString()
    const href = query ? `${action.route}?${query}` : action.route

    return (
      <Link className={className} to={ href }>{ children }</Link>
    )
  }
}

type EquipmentActionsP = {
  equipment: UseEquipmentByStickerT['equipment'],
  isBorrowed: boolean,
}

function EquipmentActions({ equipment, isBorrowed }: EquipmentActionsP) {
  const { t } = useTranslation()

  let actions: ActionProps[] = [...nonCylinderActions]
  if (equipment.equipmentType === 'cylinder') {
    actions = [...cylinderActions, ...actions]
  }
  if (isBorrowed) {
    actions.push(returnAction)
  }


  return (<>
    <h1>Actions</h1>
    <Stack gap={1}>
      {actions.map((action, idx) => (
        <ActionLink action={action} equipment={equipment} key={idx}>
          <Row>
            <Col xs={2}><action.icon/></Col>
            <Col>{t(action.text)}</Col>
          </Row>
        </ActionLink>
      ))}
    </Stack>
  </>)
}

export default EquipmentActions
