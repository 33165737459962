import { omit, compact } from 'lodash-es'

// https://github.com/ericgio/react-bootstrap-typeahead/blob/HEAD/docs/API.md
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'

import { usePurchases, UsePurchasesT } from 'client/src/queries'

type Purchase = UsePurchasesT['purchases'][number]
type PurchasePickerP = Omit<
  Parameters<typeof Typeahead>[0],
  'isLoading' | 'options' | 'labelKey' | 'selected' | 'onChange'
> & {
  selected: Array<Purchase['id']>,
  onChange: (purchaseIds: Array<Purchase['id']>) => void | Promise<void>
}

function PurchasePicker(props: PurchasePickerP) {
  const { isLoading, data } = usePurchases()
  const purchases = data?.purchases || []

  const taProps = omit(props, ['selected', 'onChange'])
  const selected = compact(props.selected.map(pId => purchases.find(p => p.id === pId)))
  const onChange = props.onChange

  return (
    <Typeahead
      {...taProps}
      isLoading={isLoading}
      options={ purchases }
      labelKey={ 'id' }
      selected={ selected }
      // @ts-expect-error our option type is Purchase here
      onChange={ (selected: Purchase[]) => onChange(selected.map(p => p.id)) }
    />
  )
}

export default PurchasePicker
