import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { isValidPhoneNumber, Country } from 'react-phone-number-input'

import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import { EnvelopeAt, Phone } from 'react-bootstrap-icons'

import { addAlert } from '../state/alerts'
import { useSearchString } from '../lib/searchState'
import { useSession, useRequestToken, useValidateToken } from 'client/src/queries'
import type { RequestTokenParams } from 'client/src/queries'

import { UserDetails } from './UserForm'

function ButtonLoadingSpinner({ loading }: { loading: boolean }) {
  if (loading) {
    return <Spinner size='sm' variant='secondary' animation='border' />
  } else {
    return null
  }
}

const EmailLoginVld = z.object({
  email: z.string().email(),
})
type EmailLoginT = z.infer<typeof EmailLoginVld>

type TokenRequester = ReturnType<typeof useRequestToken>['requestToken']
type RequestP = {
  requestToken: TokenRequester,
  redirectUrl: URL,
}

function EmailLogin({ requestToken, redirectUrl }: RequestP) {
  const { t } = useTranslation()

  const { register, handleSubmit, formState: { errors } } = useForm<EmailLoginT>({
    resolver: zodResolver(EmailLoginVld),
  })

  const onSubmit = (data: EmailLoginT) => requestToken.mutateAsync(
    {identifier: data.email, method: 'email', url: redirectUrl.toString()},
    {
      onError: (e) => {
        const error = e as Error
        addAlert({level: 'error', message: t(`Error: ${error.message}`)})
      },
    }
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>{ t('Email address') }</Form.Label>
        <Form.Control
          {...register('email')}
          type="text"
          placeholder={t('Enter email')}
          isInvalid={ 'email' in errors }
        />
        <Form.Control.Feedback type="invalid">
          { t(errors.email?.message || 'Error') }
        </Form.Control.Feedback>

        <Form.Text className="text-muted">
          { t('We\'ll never share your email with anyone else.') }
        </Form.Text>
      </Form.Group>

      <Button
        variant="primary" type="submit" disabled={ requestToken.isLoading }
      >
        <ButtonLoadingSpinner loading={ requestToken.isLoading } />
        { t('Submit') }
      </Button>
    </Form>
  )
}


type PhoneLoginT = { phone: string }
type Method = RequestTokenParams['method']
type PhoneLoginP = RequestP & {
  setRequestDetails: (newDetails: RequestDetails) => void
}

function PhoneLogin({ requestToken, setRequestDetails, redirectUrl }: PhoneLoginP) {
  const { t } = useTranslation()

  const { control, watch, handleSubmit, formState: { errors } } = useForm<PhoneLoginT>({})
  const phone = watch('phone')

  const [method, setSendMethod] = useState<Method>('otp')

  const onSubmit = async (data: PhoneLoginT) => {
    setRequestDetails(
      {to: data.phone, isExternalOtp: method === 'otp'}
    )
    await requestToken.mutateAsync(
      {identifier: data.phone, method: method, url: redirectUrl.toString()},
      {
        onError: (e) => {
          const error = e as Error
          addAlert({level: 'error', message: t(`Error: ${error.message}`)})
        },
      }
    )
  }

  const sendMethodByCountry: Partial<Record<Country, Method>> = {
    'ID': 'otp',
    'US': 'phone',
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="formPhone">
        <Form.Label>{ t('Phone number') }</Form.Label>
        { phone ?
          <>
            <div>{t('We\'ll send a one-time link')} to <mark>{phone}</mark>.</div>
          </> :
          <></>
        }
        <PhoneInputWithCountry
          name='phone'
          control={control}
          onCountryChange={(country) => {
            setSendMethod(sendMethodByCountry[country] as Method)
          }}
          rules={{
            required: t('Phone number is required'),
            validate: (v: string) => isValidPhoneNumber(v) || t('Invalid phone number'),
          }}
          placeholder={t('Enter phone number')}
          error={ errors.phone && errors.phone.message }
          defaultCountry='ID'
          countries={['ID', 'US']}
          addInternationalOption={false}
          className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
        />

        <Form.Control.Feedback type="invalid">
          { t(errors.phone?.message || 'Error') }
        </Form.Control.Feedback>

        <Form.Text className="text-muted">
          { t('We\'ll never share your phone number with anyone else.') }
        </Form.Text>
      </Form.Group>

      <Button
        variant="primary" type="submit" disabled={ requestToken.isLoading }
      >
        <ButtonLoadingSpinner loading={ requestToken.isLoading } />
        { t('Submit') }
      </Button>
    </Form>
  )
}


const LoginCodeVld = z.object({
  code: z.string().min(5, 'Too short'),
})
type LoginCodeT = z.infer<typeof LoginCodeVld>

function CodeLogin(
  { validateToken, requestDetails, submit = 'Submit' }: { validateToken: ReturnType<typeof useValidateToken>, requestDetails?: RequestDetails, submit?: string }
) {
  const { t } = useTranslation()

  const { register, handleSubmit, formState: { errors } } = useForm<LoginCodeT>({
    resolver: zodResolver(LoginCodeVld),
  })

  const onSubmit = async (data: LoginCodeT) => {
    await validateToken.mutateAsync( {
        token: data.code,
        to: requestDetails?.to,
        isExternalOtp: requestDetails?.isExternalOtp,
      })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>{ t('Login code') }</Form.Label>
        <Form.Control
          {...register('code')}
          type="text"
          placeholder={t('Enter login code')}
          isInvalid={ 'code' in errors }
        />

        <Form.Control.Feedback type="invalid">
          { t(errors.code?.message || 'Error') }
        </Form.Control.Feedback>
      </Form.Group>

      <Button
        variant="primary" type="submit" disabled={ validateToken.isLoading }
      >
        <ButtonLoadingSpinner loading={ validateToken.isLoading } />
        { t(submit) }
      </Button>
    </Form>
  )
}

type RequestDetails = {to?: string, isExternalOtp?: boolean}
function Login() {
  const { t } = useTranslation()

  const [ activeKey, setActiveKey ] = useState('sms')
  const [ urlToken, setToken ] = useSearchString('', 'token')
  const [ destination, _setDestination ] = useSearchString('', 'destination')

  const { isSuccess, isLoading, isError, data, error } = useSession()
  const { requested, requestToken, reset } = useRequestToken()
  const validateToken = useValidateToken()

  const [requestDetails, setRequestDetails] = useState<RequestDetails>({})

  // add an alert as a side effect of redirecting after login
  const user = isSuccess && data.user
  const showValidationMsg = user ? (
      !user.accessApp
      && !user.accessField
      && !user.accessOps
      && !user.accessAdmin
  ) : false
  useEffect(() => {
    if (showValidationMsg) {
      addAlert({
        level: 'warn',
        message: t('We are still confirming your account information. If you have questions, please contact Recoolit through Whatsapp.'),
      })
    }})


  // make sure we know about our token/possibly use it to log in
  useEffect(() => {
    if (urlToken) {
      validateToken.mutate({token: urlToken})
    }
  }, [urlToken])

  // if our user is valid, let's navigate to the home page
  if (isLoading) {
    return <Spinner variant='primary' animation='border' />
  }

  if (isError) {
    return <div>{ (error as Error).message }</div>
  }

  if (data?.user) {
    return <Navigate to={ destination || '/' } replace={true} />
  }

  // "user" is validated but there's no user
  // let them register
  if (validateToken.isSuccess && isSuccess && !data?.user) {
    return <UserDetails phoneOtpRequest={{phone: requestDetails.to}} />
  }

  // if the login has already failed, inform the user
  if (validateToken.error) {
    return (
      <Card border="danger">
        <Card.Body>
          <Card.Title>
            { t('Login Failed') }
          </Card.Title>

          <Card.Text>
            { t('Your login code was invalid.') }
          </Card.Text>

          <Button variant="primary" onClick={() => { setToken(''); validateToken.reset() }}>
            { t('Try again') }
          </Button>
        </Card.Body>
      </Card>
    )
  }

  // if we have a token in the store, we're trying to log in
  if (urlToken) {
    return (
      <Card>
        <Card.Body>
          <Card.Title>
            { t('Checking login token') }...
          </Card.Title>
          <Card.Text>
            { t('Please wait') }...
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  if (requested) {
    return (
      <Card>
        <Card.Body>
          <Card.Title>
            { t('Login Link Sent') }
          </Card.Title>

          <Card.Text>
            { t('Click on the link you receive to finish log-in, or enter login code below:') }
          </Card.Text>

          <CodeLogin requestDetails={requestDetails} validateToken={validateToken} submit="Finish Login" />

          <Card.Text className="mt-2">
            { t("Didn't receive login link? Click here or reach out to Recoolit for help.") }:
          </Card.Text>

          <Button variant="primary" onClick={reset}>
            { t('Try again') }
          </Button>
        </Card.Body>
      </Card>
    )
  }

  // redirect back to current page
  const redirectUrl = new URL(window.location.origin + window.location.pathname)
  if (destination) {
    redirectUrl.search = (new URLSearchParams({ destination })).toString()
  }

  return (
    <Accordion activeKey={ activeKey } onSelect={ (key) => setActiveKey(key as string) }>

      <Accordion.Item eventKey="sms">
        <Accordion.Header>
          <Phone /> &nbsp; { t('Use Phone') }</Accordion.Header>
        <Accordion.Body>
          <PhoneLogin requestToken={requestToken} setRequestDetails={setRequestDetails} redirectUrl={ redirectUrl }/>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="email">
        <Accordion.Header>
          <EnvelopeAt />&nbsp;{ t('Use Email') }
        </Accordion.Header>
        <Accordion.Body>
          <EmailLogin requestToken={requestToken} redirectUrl={ redirectUrl }/>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="code">
        <Accordion.Header>{ t('Use login code') }</Accordion.Header>
        <Accordion.Body>
          <CodeLogin validateToken={validateToken} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default Login;
