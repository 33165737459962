import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import { Camera } from 'react-bootstrap-icons'

import { addAlert } from '../state/alerts'
import {
  useUpdateEventWithPhotos,
  useSession,
} from 'client/src/queries'

import { validImageFileVld, PhotoInputField } from './FormFields'
import CreateProgressModal from './CreateProgressModal'
import PhoneOtpRequest from './PhoneOtpRequest'
import type { PhoneOtpRequestT } from './PhoneOtpRequest'
import PhoneOtpValidate from './PhoneOtpValidate'

const CreateUserVld = z.object({
  // we don't require phone here, assume it's being passed in
  name: z.string().min(5, 'Name is required'),
  company: z.string().min(5, 'Company information is required'),
  companyContact: z.string().min(5, 'Company information is required'),
  companyLocation: z.string().min(5, 'Company information is required'),
  certification: z.string().optional(),
  idPhoto: validImageFileVld,
})

type CreateUserT = z.infer<typeof CreateUserVld>

function UserDetails({phoneOtpRequest}: {phoneOtpRequest: Partial<PhoneOtpRequestT>}) {
  const { t } = useTranslation()

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos({specialEvents: 'technicianRegistration'})

  const {
    register, control, handleSubmit, formState: { errors }, reset: resetForm,
  } = useForm<CreateUserT>({
    resolver: zodResolver(CreateUserVld),
  })

  const navigate = useNavigate()
  const againButton = {
    successLabel: 'Ok',
    successFn: () => {
      navigate('/')
      addAlert({
          level: 'warn',
          message: t('We are still confirming your account information. If you have questions, please contact Recoolit through Whatsapp.'),
      })
    },
  }

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} againButton={againButton} />
    <Row><Col className="mx-auto" md="8" lg="6">

      <p>{t('Phone Number')}: {phoneOtpRequest.phone} ✅</p>
      <p>
        { t('Please fill out this form to register as a Recoolit user') }
      </p>

      <Form onSubmit={ handleSubmit((data: Partial<CreateUserT>) => mutate.mutate(
        { event: {...data, phone: phoneOtpRequest.phone}, type: 'user' })) }>
        <Form.Group controlId="name" className="mb-3">
          <Form.Label>
            { t('Name (according to government id)') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter name') }
            {...register('name')}
            isInvalid={ 'name' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.name?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="company" className="mb-3">
          <Form.Label>
            { t('Company Name') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter company name') }
            {...register('company')}
            isInvalid={ 'company' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.company?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="companyContact" className="mb-3">
          <Form.Label>
            { t('Company contact information (email or WA)') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter company contact') }
            {...register('companyContact')}
            isInvalid={ 'companyContact' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.companyContact?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="companyLocation" className="mb-3">
          <Form.Label>
            { t('Company Address') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter company address') }
            {...register('companyLocation')}
            isInvalid={ 'companyLocation' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.companyLocation?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="certification" className="mb-3">
          <Form.Label>
            { t('Certification') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter certification (if any)') }
            {...register('certification')}
            isInvalid={ 'certification' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.certification?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="idPhoto" className="mb-3">
          <Form.Label><Camera />&nbsp;{ t('KTP photo') }</Form.Label>
          <PhotoInputField control={ control } name="idPhoto" />
          <Form.Text id="ktpHelpText">{ t('If you don\'t have your KTP, you can submit a selfie instead, and send us your KTP later.') }</Form.Text>
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

function UserForm() {
  const { t } = useTranslation()
  const [ phoneOtpRequest, setPhoneOtpRequest ] = useState<Partial<PhoneOtpRequestT>>({})
  const [ validated, setValidated ] = useState(false)
  const { isLoading, isSuccess, isError, data, error } = useSession()

  // add an alert as a side effect of redirecting after login
  const user = isSuccess && data.user
  const showValidationMsg = user ? (
      !user.accessApp
      && !user.accessOps
      && !user.accessAdmin
  ) : false
  useEffect(() => {
    if (showValidationMsg) {
      addAlert({
        level: 'warn',
        message: t('We are still confirming your account information. If you have questions, please contact Recoolit through Whatsapp.'),
      })
    }})

  // state for if the user is logged in
  // if our user is valid, let's navigate to the home page
  if (isLoading) {
    return <Spinner variant='primary' animation='border' />
  }

  if (isError) {
    return <div>{ (error as Error).message }</div>
  }

  if (data?.user) {
    return <Navigate to='/' replace={true} />
  }

  const body = (
    <>
      <Row><Col className="mx-auto" md="8" lg="6"><h2>{
        validated ? t('User Registration') : t('Validate Phone Number')
      }</h2></Col></Row>
    { validated ? (
      <UserDetails phoneOtpRequest={phoneOtpRequest} /> )
    : (
      <>
        <PhoneOtpRequest
          phoneOtpRequest={phoneOtpRequest}
          setPhoneOtpRequest={setPhoneOtpRequest}
        ></PhoneOtpRequest>
      { phoneOtpRequest.isRequested && (
        <PhoneOtpValidate
        setValidated={setValidated}
        validateParams={{
          to: phoneOtpRequest.phone,
          // WA countries will always be external OTP required
          // but we can't tell at this stage if we're going to use external OTP (vs internal token gen) for non-WA countries, so default to it.
          isExternalOtp: true,
        }}
        />)
      }
      </>) } </>)
  return body
}

export default UserForm

export { UserDetails }
