import { ReactNode, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import ReactFlow, {
  Controls,
  ControlButton,
  Background,
  MiniMap,
  Handle,
  Position,
  PanOnScrollMode,
  MarkerType,
  applyNodeChanges,
  NodeChange,
  ReactFlowInstance,
} from 'reactflow';

import 'reactflow/dist/style.css'

import Accordion from 'react-bootstrap/Accordion'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Stack from 'react-bootstrap/Stack'

import { Funnel } from 'react-bootstrap-icons';
import { XCircle } from 'react-bootstrap-icons';

import { useGas, UseGasT, GraphFilter } from 'client/src/queries'
import { useSearchString, useSearchObject, useSearchFlag } from '../lib/searchState'

import { Recovery, Transfer, Destruction, QC, Weight, Vacuum, Move, Identification } from './EventCards'
import EquipmentPicker from './EquipmentPicker'
import PurchasePicker from './PurchasePicker'
import DateStr from './DateStr'
import { isEmpty, countBy, sum, groupBy, sumBy, floor } from 'lodash-es';
import dayjs from 'dayjs';

type Node = UseGasT['nodes'][number]

function DateRange ({ data }: { data: Node['data'] }) {
  if (data.minDate === data.maxDate) {
    return <DateStr date={ data.minDate } />
  } else {
    return <>
      <DateStr date={ data.minDate } /> -- <DateStr date={ data.maxDate } />
    </>
  }
}

type GraphState = {
  detailsFor: string,
  eqIds: string[],
  purchases: string[],
}

function useGraphState() {
  return useSearchObject<GraphState>({ detailsFor: '', eqIds: [], purchases: [] })
}

function useDetailsFor() {
  const [gs, setGs] = useGraphState()
  return [gs.detailsFor, (v: string) => setGs({ detailsFor: v })] as const
}

function useEqIds() {
  const [gs, setGs] = useGraphState()
  return [gs.eqIds, (v: string[]) => setGs({ eqIds: v })] as const
}

function usePurchaseIds() {
  const [gs, setGs] = useGraphState()
  return [gs.purchases, (v: string[]) => setGs({ purchases: v })] as const
}

type TransferNodeP = {
  id: string,
  data: Node['data'],
}

function toggleNodeFiltered(
  nodeType: Node['data']['nodeType'], dataId: string, gs: GraphState, setGs: ReturnType<typeof useGraphState>[1]
) {
  const key = nodeType === 'sale' ? 'purchases' : 'eqIds'
  const existingIds = gs[key]
  const inFilter = existingIds.includes(dataId)

  const newCodes = inFilter ? existingIds.filter(id => id !== dataId) : [...existingIds, dataId]
  setGs({ [key]: newCodes })
}

function TransferNode ({ id, data }: TransferNodeP) {
  const [gs, setGs] = useGraphState()

  const nodeType = data.nodeType

  // calculate in-filter
  const dataId = nodeType === 'sale' ? data.purchaseId : data.equipmentId
  const allInFilter = nodeType === 'sale' ? gs.purchases : gs.eqIds
  const inFilter = allInFilter.includes(dataId)

  return (
    <Card style={ { width: '230px' } } border={ inFilter ? 'primary' : null }>
      { nodeType === 'recovery' ? null : (
        <Handle type="target" position={Position.Top} />
      )}

      <Card.Header>
        <Stack direction="horizontal" gap={ 1 } style={ { width: '100%' } }>
          <span className="me-auto" style={ { textTransform: 'capitalize' } }>{ nodeType }</span>
          { ['recovery', 'sale'].includes(nodeType) ? null : (
            <Badge bg="primary" pill className="me-2">{ data.events.length }</Badge>
          )}

          <Button
            variant={ inFilter ? 'primary' : 'secondary' }
            size='sm'
            className="rounded-pill"
            onClick={ () => toggleNodeFiltered(nodeType, dataId, gs, setGs) }
          >
            <Funnel />
          </Button>
        </Stack>
      </Card.Header>

      <Card.Body className='p-0'>
        <ListGroup variant='flush'>
          { nodeType === 'sale' ? null : (
          <ListGroup.Item active={ false } action={ true } href={ `/equipment/${data.eqStickerCode!}` } target="_blank">
            { data.eqStickerCode }
          </ListGroup.Item>
          )}
          <ListGroup.Item>
            <DateRange data={ data } />
          </ListGroup.Item>
          <ListGroup.Item>
            <Button size='sm' onClick={() => setGs({ detailsFor: id })}>
              Show Details
            </Button>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>

      { nodeType === 'sale' ? null : (
        <Handle type="source" id="saleOut" position={Position.Bottom} style={ { left: 30 } } />
      )}

      { ['sale', 'destruction'].includes(nodeType) ? null : (
        <Handle type="source" id="gasOut" position={Position.Bottom} />
      )}
    </Card>
  )
}

function NodeDetails({ node }: { node: Node }) {
  const { t } = useTranslation()

  const events = node.data.events
    .map(e => { e.ts = dayjs(e.ts).toDate(); return e })
    .sort((a, b) => b.ts.valueOf() - a.ts.valueOf())

  const cards: ReactNode[] = []

  for (const event of events) {
    let card: ReactNode
    if (event.type === 'recovery') {
      card = <Recovery event={ event.event } />
    } else if (event.type === 'destruction') {
      card = <Destruction event={ event.event } />
    } else if (event.type === 'transfer') {
      card = <Transfer event={ event.event } direction='In' />
    } else if (event.type === 'qc') {
      card = <QC event={ event.event } />
    } else if (event.type === 'weight') {
      card = <Weight event={ event.event } />
    } else if (event.type === 'vacuum') {
      card = <Vacuum event={ event.event } />
    } else if (event.type === 'move') {
      card = <Move event={ event.event } />
    } else if (event.type === 'ident') {
      card = <Identification event={ event.event } />
    }

    cards.push(
      <Accordion.Item eventKey={ event.event.id } key={ event.event.id }>
        <Accordion.Header>
          <DateStr date={ event.ts } className="text-secondary" />
          <span className="ms-2" style={ { textTransform: 'capitalize' } }>{ event.type }</span>
        </Accordion.Header>
        <Accordion.Body>
          { card }
        </Accordion.Body>
      </Accordion.Item>
    )
  }

  return (
    <Modal.Body>
      <Card style={ { borderLeft: 0, borderRight: 0, borderTop: 0 } }>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <strong>{ t('Node Type') }</strong>: { node.data.nodeType }
          </ListGroup.Item>

          { node.data.nodeType === 'sale' ? null : (
          <ListGroup.Item active={ false } action={ true } href={ `/equipment/${node.data.eqStickerCode!}` } target="_blank">
            <strong> { t('Cylinder') } </strong>: { node.data.eqStickerCode }
          </ListGroup.Item>
          )}

          <ListGroup.Item>
            <strong>
              { node.data.minDate === node.data.maxDate ? t('Date') : t('Node interval') }
            </strong> : <DateRange data={ node.data } />
          </ListGroup.Item>

          { node.data.nodeType !== 'sale' ? null : (<>
          <ListGroup.Item>
              <strong>{ t('CO2e') }</strong>: { node.data.purchase.co2eKg } kg
          </ListGroup.Item>

          <ListGroup.Item>
            <strong>{ t('Buyer') }</strong>: { node.data.purchase.userName }
          </ListGroup.Item>

          <ListGroup.Item>
            <strong>{ t('Receipt') }</strong>
            : <a href={ `https://registry.recoolit.com/purchases/${ node.data.purchase.id }`} target="_blank">
              { node.data.purchase.id }
            </a>
          </ListGroup.Item>

          </>)}

          { node.data.nodeType === 'sale' ? null : (<>
          <ListGroup.Item>
            <Stack direction='horizontal' gap={2}>
              <span><strong>{ t('Gas in') }</strong>: { node.data.weight.in } g</span>
              <span><strong>{ t('Gas out') }</strong>: { node.data.weight.out } g</span>
              <span><strong>{ t('Remaining') }</strong>: { node.data.weight.remaining } g</span>
            </Stack>
          </ListGroup.Item>

          <ListGroup.Item>
            <Stack direction='horizontal' gap={2}>
              <span><strong>{ t('Gas Sold') }</strong>: { node.data.weight.sold } g</span>
              <span><strong>{ t('Remaining for Sale') }</strong>: { node.data.weight.saleRemaining } g</span>
            </Stack>
          </ListGroup.Item>
          </>)}

        </ListGroup>
      </Card>

      <Accordion flush>
        { cards }
      </Accordion>
    </Modal.Body>
  )
}

type NodeDetailsModalP = {
  isLoading: boolean,
  nodes: Node[],
}

function NodeDetailsModal({ isLoading, nodes }: NodeDetailsModalP) {
  const { t } = useTranslation()

  const [detailsFor, setDetailsFor] = useDetailsFor()

  const handleClose = () => setDetailsFor('')

  if (!detailsFor) return null;

  let body: ReactNode
  const node = nodes.find(node => node.id === detailsFor)

  if (isLoading) {
    body = (<Modal.Body>{ t('Loading...') }</Modal.Body>);

  } else if (!node) {
    body = (<Modal.Body>{ t('Node not found...') }</Modal.Body>);

  } else {
    body = (<NodeDetails node={node} />);
  }

  return (
    <Modal show={ true } onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t(' Node')} <span className="text-secondary">{ detailsFor }</span>
        </Modal.Title>
      </Modal.Header>

      { body }

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          { t('Close') }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function GraphStats({ graph }: { graph: UseGasT }) {
  const counts: Record<string, Record<string, number>> = {
    Nodes: countBy(graph.nodes, (n) => n.data.nodeType),
    Edges: countBy(graph.edges, (e) => e.data.type),
    Events: countBy(graph.nodes.map(n => n.data.events).flat(), (e) => e.type),
  }

  const recoveries = graph.edges.filter(e => e.data.type === 'recovery')
  const byGas = groupBy(recoveries, (e) => e.data.gas)
  counts['Recoveries'] = Object.fromEntries(Object.entries(byGas).map(
    ([gas, edges]) => [gas, edges.length]
  ))
  counts['Gas Recovered (kg)'] = Object.fromEntries(Object.entries(byGas).map(
    ([gas, edges]) => [gas, sumBy(edges, (e) => e.data.weight) / 1000]
  ))

  counts['Links per sale'] = {'3': 0, '4': 0, '5': 0, '6+': 0}
  counts['Recoveries per sale'] = {'1': 0, '2': 0, '3': 0, '4+': 0}
  counts['CO2e per sale'] = {'1 tons': 0, '2 tons': 0, '3 tons': 0, '4+ tons': 0}

  const sales = graph.nodes.filter(n => n.data.nodeType === 'sale')
  for (const sale of sales) {
    const links = graph.edges.filter(e => e.target === sale.id)
    if (links.length === 0) continue;

    counts['Links per sale'][links.length >= 6 ? '6+' : links.length.toString()] += 1

    const sources = links.map(e => graph.nodes.find(n => n.id === e.source))
    const rCount = countBy(sources, n => n.data.nodeType)['recovery'] || 0
    counts['Recoveries per sale'][rCount >= 4 ? '4+' : rCount.toString()] += 1

    let tons = (sale.data.purchase.co2eKg / 1000).toFixed()
    tons = tons === '0' ? '1' : tons
    counts['CO2e per sale'][Number(tons) >= 4 ? '4+ tons' : `${tons} tons`] += 1
  }

  return (
    <Modal.Body>
      { Object.entries(counts).map(([t, counts]) =>
        <Table striped size='sm' key={t}>
          <thead><tr>
            <th style={ { whiteSpace: 'nowrap' } }>{t}</th>
            { Object.keys(counts).map((k, idx) => <th key={idx}>{k}</th>) }
            <th>Total</th>
          </tr></thead>

          <tbody><tr>
            <td>Count:</td>
            { Object.values(counts).map((v, idx) => <td key={idx}>{ floor(v, 2) }</td>) }
            <td> { floor(sum(Object.values(counts)), 2) } </td>
          </tr></tbody>
        </Table>
      )}
    </Modal.Body>
  )
}

function GraphStatsModal({ isLoading, graph }: { isLoading: boolean, graph: UseGasT }) {
  const { t } = useTranslation()

  const [showStats, setShowStats] = useSearchFlag('stats')
  const handleClose = () => setShowStats(false)

  if (!showStats) return null;

  let body: ReactNode
  if (isLoading) {
    body = (<Modal.Body>{ t('Loading...') }</Modal.Body>);
  } else {
    body = (<GraphStats graph={graph} />);
  }

  return (
    <Modal show={ true } onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          { t('Graph Statistics') }
        </Modal.Title>
      </Modal.Header>

      { body }

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          { t('Close') }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const nodeTypes = { 'transferNode': TransferNode }

function TransferGraph(filters: GraphFilter) {
  const { t } = useTranslation()

  const [ lastJump, setLastJump ] = useState<string | null>(null)
  const [flow, setFlow] = useState<ReactFlowInstance | null>(null)

  const eqIds = filters.eqIds
  const { isLoading, error, data, updateNodes } = useGas(
    filters,
    (data: UseGasT) => {
      for (const node of data.nodes) {
        if (node.type === 'group') {
          node.draggable = false
        }
      }

      for (const edge of data.edges) {
        const desc = edge.data.type === 'sampling' ? ' (sampling)' : ''
        edge.label = `${(edge.data.weight / 1000).toFixed(2)} kg of ${edge.data.gas} ${desc}`
        edge.markerEnd = {
          color: 'black',
          width: 20,
          height: 20,
          type: MarkerType.ArrowClosed,
        }
      }

      return data
    }
  )

  const jump = () => {
    const jumps = (data?.nodes || []).filter(n => (
      eqIds.includes(n.data.equipmentId) || filters.purchaseIds.includes(n.data.purchaseId)
    ))

    if (isEmpty(jumps)) {
      setLastJump(null)
      return
    }

    let curIdx = jumps.map(n => n.id).indexOf(lastJump)
    curIdx = (curIdx + 1) % jumps.length

    const curJump = jumps[curIdx].id
    if (flow) {
      const curNode = data.nodes.find(n => n.id === curJump)
      flow.setCenter(curNode.position.x, curNode.position.y + 200, { zoom: 0.9 })
    }

    setLastJump(curJump)
  }

  // if data reloads, jump to last jumped-to node
  useEffect(() => {
    if (!flow || !lastJump || !data) return;

    const curNode = data.nodes.find(n => n.id === lastJump)
    if (curNode) {
      flow.setCenter(curNode.position.x, curNode.position.y + 200, { zoom: 0.9 })
    } else {
      jump()
    }
  }, [data])

  if (isLoading) {
    return (<Row><Col>{ t('Loading...') }</Col></Row>)
  }

  if (error) {
    return (<Row><Col>{ t('Error') }</Col></Row>)
  }

  return (
    <Row><Col style={{ height: '600px' }}>
      <NodeDetailsModal nodes={data.nodes || []} isLoading={isLoading} />
      <GraphStatsModal graph={data} isLoading={isLoading} />

      <ReactFlow
        onInit={setFlow}
        nodes={data?.nodes || []}
        edges={data?.edges || []}
        onNodesChange={
          (changes: NodeChange[]) => updateNodes(applyNodeChanges(changes, data.nodes))
        }
        nodeTypes={nodeTypes}
        nodesConnectable={false}
        edgesFocusable={false}
        panOnScroll={true}
        panOnScrollMode={ PanOnScrollMode.Free }
        defaultViewport={ { x: 20, y: 20, zoom: 0.7 } }
      >
        <Background />
        <Controls showInteractive={ false } showFitView={ false }>
          <ControlButton
            title="next filtered"
            aria-label="next filtered"
            onClick={jump}
          >⇢</ControlButton>
        </Controls>

        <MiniMap nodeColor="red" nodeStrokeWidth={3} />
      </ReactFlow>
    </Col></Row>
  )
}

function TransferChains () {
  const { t } = useTranslation()

  const [eqIds, setEqIds] = useEqIds()
  const [purchaseIds, setPurchaseIds] = usePurchaseIds()
  const [eqCond, setEqCond] = useSearchString<'and' | 'or'>('or', 'eqCond')
  const [terminatingIn, setTerminatingIn] =
    useSearchString<'storage' | 'destruction' | 'sale' | 'any'>('storage', 'terminatingIn')
  const [startingOn, setStartingOn] = useSearchString('', 'startingOn')
  const [maxDate, setMaxDate] = useSearchString('', 'upto')
  const [_showStats, setShowStats] = useSearchFlag('stats')

  return (
    <div>
      <h1>{ t('Transfer Chains') }</h1>

      <Row className="mb-1">
        <Col sm="12" md="6">
          <Stack direction="vertical" gap={2}>
            <Stack direction="horizontal" gap={2}>
              <label htmlFor="chainEqFilter" style={ { whiteSpace: 'nowrap' } }>{ t('Including') }:</label>
              <InputGroup>
                <Button
                  variant={ eqCond === 'and' ? 'primary' : 'outline-secondary' }
                  onClick={ () => setEqCond('and') }>
                  { t('All of') }
                </Button>
                <Button
                  variant={ eqCond === 'or' ? 'primary' : 'outline-secondary' }
                  onClick={ () => setEqCond('or') }>
                  { t('Any of') }
                </Button>
                <EquipmentPicker
                  types={['cylinder']}
                  id="chainEqFilter"
                  multiple
                  placeholder={ t('Pick equipment to include') }
                  clearButton={true}
                  selected={ eqIds }
                  onChange={ setEqIds }
                />
              </InputGroup>
            </Stack>

            <Stack direction="horizontal" gap={2}>
              <label htmlFor="startingOn" style={ { whiteSpace: 'nowrap' } }>{ t('Recoveries since') }:</label>
              <InputGroup>
                <Form.Control
                  id="startingOn"
                  type="date"
                  max={ (new Date()).toISOString().split('T')[0] }
                  value={ startingOn }
                  onChange={ (e) => setStartingOn(e.target.value) }
                />
                <Button
                  variant={ startingOn ? 'outline-primary' : 'outline-secondary' }
                  disabled={ !startingOn }
                  onClick={() => setStartingOn('')}
                ><XCircle /></Button>
              </InputGroup>
            </Stack>

            <Stack direction="horizontal" gap={2}>
              <label htmlFor="upto" style={ { whiteSpace: 'nowrap' } }>{ t('Maximum date') }:</label>
              <InputGroup>
                <Form.Control
                  id="upto"
                  type="date"
                  max={ (new Date()).toISOString().split('T')[0] }
                  value={ maxDate }
                  onChange={ (e) => setMaxDate(e.target.value) }
                />
                <Button
                  variant={ maxDate ? 'outline-primary' : 'outline-secondary' }
                  disabled={ !maxDate }
                  onClick={() => setMaxDate('')}
                ><XCircle /></Button>
              </InputGroup>
            </Stack>
          </Stack>
        </Col>

        <Col sm="12" md="6">
          <Stack direction="vertical" gap={2}>
            <Stack direction="horizontal" gap={2}>
              <label htmlFor="purchaseFilter" style={ { whiteSpace: 'nowrap' } }>{ t('Purchases') }:</label>
              <InputGroup>
              <PurchasePicker
                id="purchaseFilter"
                multiple
                placeholder={ t('Pick purchases to include') }
                clearButton={true}
                selected={ purchaseIds }
                onChange={ setPurchaseIds }
                />
              </InputGroup>
            </Stack>

            <Stack direction="horizontal" gap={ 2 }>
              <span>{ t('Chain final state:') }</span>
              <Form.Check inline name="terminalState" type="radio"
                label={ t('destroyed') }
                value="destruction"
                checked={ terminatingIn === 'destruction' }
                onChange={ () => setTerminatingIn('destruction') }
                className="me-0"
              />
              <Form.Check inline name="terminalState" type="radio"
                label={ t('stored gas') }
                value="storage"
                checked={ terminatingIn === 'storage' }
                onChange={ () => setTerminatingIn('storage') }
                className="me-0"
              />
              <Form.Check
                inline name="terminalState" type="radio"
                label={ t('sale') }
                value="sale"
                checked={ terminatingIn === 'sale'}
                onChange={ () => setTerminatingIn('sale') }
                className="me-0"
              />
              <Form.Check
                inline name="terminalState" type="radio"
                label={ t('any') }
                value="any"
                checked={ terminatingIn === 'any'}
                onChange={ () => setTerminatingIn('any') }
                className="me-0"
              />
            </Stack>

            <Stack direction="horizontal" gap={2}>
              <Button variant="primary" className="mx-auto" onClick={ () => setShowStats(true) }>
                Show Stats
              </Button>
            </Stack>

          </Stack>
        </Col>
      </Row>

      <TransferGraph
        eqIds={ eqIds }
        eqCond={ eqCond }
        terminatingIn={ terminatingIn }
        startingOn={ startingOn }
        maxDate={ maxDate }
        purchaseIds={ purchaseIds }
      />
    </div>
  )
}

export default TransferChains;
