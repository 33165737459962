import { ComponentProps } from 'react';
import dayjs from 'dayjs'

import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

type DateProps = ComponentProps<'span'> & {
  date: Date,
  format?: string,
}

function DateStr({ date, format, ...props }: DateProps) {
  const dayDate = dayjs(date)
  const dateFormat = format || 'YY/MM/DD HH:mm z'

  let formatted
  if (dayDate.isValid() && dayDate.year() > 1970) {
    formatted = dayDate.format(dateFormat)
  } else {
    formatted = '∞'
  }

  return <span {...props}>{formatted}</span>
}

export default DateStr
