import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import EventImage from './EventImage'
import { useEventImage } from 'client/src/queries'

function ImageDetail() {
  const {imageId} = useParams()
  const {error, isLoading, data} = useEventImage(imageId)

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return (<div>Error: { (error as Error).message }</div>)
  }

  return (
    <div>
      <h1>Image Detail</h1>
      <table>
        <tr>
          <td>Image Id:</td>
          <td>{imageId}</td>
        </tr>
        <tr>
          <td>Event:</td>
          <td>
            <Link to={`/events/${data.meta.eventId}`}>
              {data.meta.eventType}
            </Link> ({data.meta.eventField})
          </td>
        </tr>
      </table>
      <br></br>
      <EventImage id={imageId} label='Image' />
    </div>
  )
}

export default ImageDetail;
