import { ReactNode } from 'react'
import { useParams } from 'react-router-dom';
import { startCase } from 'lodash-es';
import { useEventDetail } from 'client/src/queries';
import {
  Recovery,
  DirectionAgnosticTransfer,
  Destruction,
  QC,
  Weight,
  Vacuum,
  Move,
  Identification,
  Borrow,
} from './EventCards'
import DeleteEvent from './DeleteEvent'
import DateStr from './DateStr'

function EventDetail() {
  const {eventId} = useParams()
  const { isLoading, error, data } = useEventDetail(eventId)

  if (isLoading) {
    return (<div>Loading...</div>)
  }

  if (error) {
    return (<div>Error: { (error as Error).message }</div>)
  }

  const event = data.event
  let card: ReactNode
  if (event.type === 'recovery') {
    card = <Recovery event={ event.event } />
  } else if (event.type === 'destruction') {
    card = <Destruction event={ event.event } />
  } else if (event.type === 'transfer') {
    card = <DirectionAgnosticTransfer event={ event.event } />
  } else if (event.type === 'qc') {
    card = <QC event={ event.event } />
  } else if (event.type === 'weight') {
    card = <Weight event={ event.event } />
  } else if (event.type === 'vacuum') {
    card = <Vacuum event={ event.event } />
  } else if (event.type === 'move') {
    card = <Move event={ event.event } />
  } else if (event.type === 'ident') {
    card = <Identification event={ event.event } />
  } else if (event.type === 'borrow') {
    card = <Borrow event={ event.event } />
    // we skip returns because the API returns a borrow event for returns.
    // in the future we might want to return the return event instead?
  }

  const isDeleted = ('deletedAt' in event.event) && (event.event.deletedAt)
  let title
  if (isDeleted) {
    title = <h1><del>{startCase(event.type)}</ del> (deleted)</h1>
  }
  else {
    title = <h1>{startCase(event.type)}</h1>
  }
  return (
    <div>
      {title}
      <DateStr date={ event.ts } className="text-secondary" />
      <DeleteEvent event={ event } />
      {card}
    </div>
  )
}

export default EventDetail;

