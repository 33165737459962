import type { ErrorResponse } from '../../api-rec/src/middleware/errors'

type FetchArgs = Parameters<typeof fetch>

type ApiResult<T extends Record<string, unknown>> = {
  data: T,
  status: number,
  statusText: string,
  error: string | null,
  success: boolean,
}

type SuccessResponse = {
  success: true,
}

type ApiResponse = ErrorResponse | (SuccessResponse & Record<string, unknown>)

async function makeApiRequest<T extends ApiResponse>(...args: FetchArgs): Promise<ApiResult<T>> {
  const result = await fetch(...args)

  let data: ApiResponse = {success: false, message: 'unknown error' }

  // default the error message to the status text if it's not a 200
  let error: string | null = result.ok ? null : result.statusText

  // parse the response body; it should be json
  const text = await result.text()

  try {
    data = JSON.parse(text) as T

    // we should get both a 200 status code AND `success: true`, otherwise it's an error
    if (!(result.ok && data.success)) {
      error = data.message ? (data.message as string) : error
    }

  // if it's not json, assume it's an error string
  } catch (e) {
    data.message = text
    error = text
  }

  return {
    status: result.status,
    statusText: result.statusText,
    data: data as T,
    error,
    success: error === null,
  }
}

async function dataOrThrow<T extends ApiResponse>( ...args: Parameters<typeof makeApiRequest>) {
  const result = await makeApiRequest<T>(...args)
  if (result.success) {
    return result.data
  } else {
    throw new Error(result.error!)
  }
}

export {
  makeApiRequest,
  dataOrThrow,
}
