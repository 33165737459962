import { useTranslation } from 'react-i18next';

import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Link } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import {
  useUpdateEventWithPhotos,
} from 'client/src/queries'

import { validImageFileVld, PhotoInputField } from './FormFields'
import CreateProgressModal from './CreateProgressModal'
import ExamplePhoto from './ExamplePhoto'

import borrowExample from '../assets/borrowExample.jpg'

const CreateBorrowVld = z.object({
  technicianName: z.string().min(5, 'Name is required'),
  borrowAt: z.date().max(new Date(), 'Date must be in the past'),
  equipmentPhotos: z.tuple([validImageFileVld]).rest(validImageFileVld.or(z.undefined()).or(z.null())),
})

type CreateBorrowT = z.infer<typeof CreateBorrowVld>

function BorrowForm() {
  const { t } = useTranslation()

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos({
    specialEvents: 'equipmentBorrow',
    photoArray: 'equipmentPhotos',
  })
  const [ photoCount, setPhotoCount ] = useState(1)
  const [ photoFields, setPhotoFields] = useState<File[]>([]);

  const addPhotoField = () => {
    if ((photoCount < 10) && photoFields[photoCount - 1]) {
      setPhotoCount(photoCount + 1);
    }
  }

  const {
    register, control, handleSubmit, watch, formState: { errors }, reset: resetForm,
  } = useForm<CreateBorrowT>({
    resolver: zodResolver(CreateBorrowVld),
  })

  const photoValues = watch('equipmentPhotos')

  useEffect(() => {
    setPhotoFields(photoValues || [])
  }, [photoValues])

  const fullReset = () => {
    resetForm()
    setPhotoCount(1)
    setPhotoFields([])
  }

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={fullReset} />
    <Row><Col className="mx-auto mb-5" md="8" lg="6">
      <h2>{ t('Borrow Equipment') }</h2>

      <p>
        { t('Please fill out this form to borrow equipment.')}&nbsp;
        <Link to='/forms/return'>{t('To return equipment, click here.')} </Link>
      </p>

      <Form onSubmit={
        handleSubmit((data) =>
          mutate.mutate({ event: {...data as object, kind: 'byPhoto'}, type: 'borrow' }))
        }>
        <Form.Group controlId="technicianName" className="mb-3">
          <Form.Label>
            { t('Name of the person borrowing the equipment.') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter name') }
            {...register('technicianName')}
            isInvalid={ 'technicianName' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.technicianName?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="borrowAt" className="mb-3">
          <Form.Label>{ t('Date the equipment was borrowed') }</Form.Label>
          <Form.Control
            type="date"
            max={ (new Date()).toISOString().split('T')[0] }
            {...register('borrowAt', { valueAsDate: true })}
            isInvalid={ 'borrowAt' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.borrowAt?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        {Array.from({length: photoCount}, (_, index) => (
          <Form.Group key={`photo${index}`} controlId={`equipmentPhoto${index + 1}`} className="mb-3">
            <Form.Label>{ t('Photo of equipment (with sticker)') }</Form.Label>
            <ExamplePhoto src= {borrowExample} />
            <PhotoInputField control={control} name={`equipmentPhotos.${index}`} />
          </Form.Group>
        ))}

        <Button variant="secondary" onClick={addPhotoField} disabled={(photoCount === 10) || !photoFields[photoCount-1]} className="d-grid gap-2 d-md-flex justify-content-md-end mb-3" >
          { photoCount === 10 ? t('Maximum 10 equipment') : t('Add another photo') }
        </Button>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

export default BorrowForm;
