import { useTranslation } from 'react-i18next';

import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Link } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import { CheckCircle, XCircle, QrCodeScan } from 'react-bootstrap-icons'

import {
  useUpdateEventWithPhotos,
  useSession,
  useUser,
  UseUserT,
  useEquipmentBySticker,
  UseEquipmentByStickerT,
} from 'client/src/queries'

import CreateProgressModal from './CreateProgressModal'
import { validImageFileVld, PhotoInputField } from './FormFields'

import QrScanner from './QrScanner'

type QrValidationStatus = 'valid' | 'invalid' | 'loading' | null
type User = UseUserT['user']
type Equipment = UseEquipmentByStickerT['equipment']

function extractUserId(text: string): string {
    // taken from zod, but fixed for upper/lowercase mismatch
    // https://github.com/colinhacks/zod/blob/fc7751e1174ef3ef5ac4157bf2531217d5426054/src/types.ts#L578C21-L578C43
    const userIdRegex = /^https:\/\/app.recoolit.com\/user\/([0-9a-hjkmnp-tv-z]{26})$/

    const matches = text ? text.match(userIdRegex) : null
    return (matches && matches.length > 1) ? matches[1] : null
}

function SuccessCard({text}: {text: string}) {
    return (
    <div>
      <Card className="py-2 ps-3">
      <span>
      <CheckCircle style={{ color: 'green', display: 'inline-flex', alignItems: 'center' }}/>
      &nbsp;&nbsp;&nbsp;
      {text}
      </span>
      </Card>
      </div>
    )
}

function ScanUserQr({user, setUser}: {user: User | null, setUser: (user: User) => void}) {
  const { t } = useTranslation()

  const [qrValue, setQrValue] = useState<string | null>(null)
  const [showScanner, setShowScanner] = useState(false)
  const userId = qrValue ? extractUserId(qrValue) : null
  const { isLoading, error, data } = useUser(userId)
  useEffect(() => {
    if (data && data.success) {
      setUser(data.user)
    }
  }, [data])

  let status: QrValidationStatus = null
  if (user) {
    status = 'valid'
  } else if (error || (qrValue && !userId)) {
    status = 'invalid'
  } else if (isLoading) {
    status = 'loading'
  }


  if (status === 'loading') {
    return (<div><Button disabled><Spinner/> { t('Loading')}</Button></div>)
  }
  if (status === 'valid') {
    return <SuccessCard text={user.name} />
  }

  const onDetect = (value: string) => {
    setQrValue(value)
    setShowScanner(false)
  }

  return (
    <div>
    <Button onClick={() => setShowScanner(!showScanner)}>
      {status === 'invalid' ?
        (<><XCircle />&nbsp;Please try again</>) : <><QrCodeScan/>&nbsp;Scan QR</>
      }
    </Button>
    {showScanner && <QrScanner onDetect={onDetect} />}
    </div>
  )
}

function extractStickerId(text: string): string {
    const stickerIdRegex = /^https:\/\/app.recoolit.com\/stickers\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/

    const matches = text ? text.match(stickerIdRegex) : null
    return (matches && matches.length > 1) ? matches[1] : null
}


function ScanEquipmentQr({addEquipment}: {addEquipment: (equipment: Equipment) => void}) {
  // TODO: check against existing stickerIds?
  const { t } = useTranslation()

  const [qrValue, setQrValue] = useState<string | null>(null)
  const [showScanner, setShowScanner] = useState(false)
  const stickerId = qrValue ? extractStickerId(qrValue) : null
  const { isLoading, error, data } = useEquipmentBySticker(stickerId)

  useEffect(() => {
    if (data && data.success) {
      addEquipment(data.equipment)
      setQrValue(null)
    }
  }, [data])

  let status: QrValidationStatus = null
  if (error || (qrValue && !stickerId)) {
    status = 'invalid'
  } else if (isLoading) {
    status = 'loading'
  }

  if (status === 'loading') {
    return (<div><Button disabled><Spinner/> { t('Loading')}</Button></div>)
  }

  const onDetect = (value: string) => {
    setQrValue(value)
    setShowScanner(false)
  }

  return (
    <div>
    <Button onClick={() => setShowScanner(!showScanner)}>
      {status === 'invalid' ?
        (<><XCircle />&nbsp;Please try again</>) : <><QrCodeScan/>&nbsp;Scan QR</>
      }
    </Button>
    {showScanner && <QrScanner onDetect={onDetect} />}
    </div>
  )
}


const CreateBorrowVld = z.object({
  borrowerId: z.string().min(5, 'Technician is required'),
  borrowAt: z.date().max(new Date(), 'Date must be in the past'),
  equipmentIds: z.array(z.string()).min(1, 'At least one equipment is required'),
  shipmentPhoto: validImageFileVld,
})

type CreateBorrowT = z.infer<typeof CreateBorrowVld>

function BorrowFormQr() {
  const { t } = useTranslation()

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos({
    specialEvents: 'equipmentBorrow',
  })
  const { data } = useSession()
  const [ equipment, setEquipment] = useState<Equipment[]>([]);
  const [ borrower, setBorrower ] = useState<User | null>(null)

  const {
    register, control, handleSubmit, setValue, formState: { errors }, reset: resetForm,
  } = useForm<CreateBorrowT>({
    resolver: zodResolver(CreateBorrowVld),
  })

  useEffect(() => {
    if (borrower) {
      setValue('borrowerId', borrower.userId)
    }
  }, [borrower])

  /* TODO(Louis) explore using fieldarray
   * https://react-hook-form.com/docs/usefieldarray
   */
  useEffect(() => {
    if (equipment.length > 0) {
      setValue('equipmentIds', equipment.map(e => e.id))
    }
  }, [equipment])


  const fullReset = () => {
    resetForm()
    setBorrower(null)
    setEquipment([])
  }
  const onSubmit = handleSubmit((data) => {
    mutate.mutate({ event: {kind: 'byId', ...data as object}, type: 'borrow' })}
  )

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={fullReset} />
    <Row><Col className="mx-auto mb-5" md="8" lg="6">
      <h2>{ t('Borrow Equipment') }</h2>

      <p>
        { t('Please fill out this form to borrow equipment.')}&nbsp;
      </p>
      { data?.user?.accessOps && <p><Link to='/forms/borrowBackfill'>{t('To manually backfill a borrow, click here.')} </Link></p> }

      <Form onSubmit={onSubmit}>
        <Form.Group controlId="borrowerId" className="mb-3">
          <Form.Control as="div" isInvalid={'borrowerId' in errors}>
          <Form.Label>{ t('Please scan the QR code of the technician borrowing the equipment.') }
          </Form.Label>
            <ScanUserQr user={borrower} setUser={setBorrower} />
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            { t(errors.borrowerId?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="borrowAt" className="mb-3">
          <Form.Label>{ t('Date the equipment was borrowed') }</Form.Label>
          <Form.Control
            type="date"
            max={ (new Date()).toISOString().split('T')[0] }
            {...register('borrowAt', { valueAsDate: true })}
            isInvalid={ 'borrowAt' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.borrowAt?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="equipmentIds" className="mb-3">
          <Form.Control as="div" isInvalid={'equipmentIds' in errors}>
          {equipment.map((e) => (
              <SuccessCard key={e.id} text={e.stickerCode} />
          ))}
          <Form.Label>{t('Scan equipment stickers')}</Form.Label>
            <ScanEquipmentQr addEquipment={(e) => setEquipment([...equipment, e])}/>
          </Form.Control>
            <Form.Control.Feedback type="invalid">
              { t(errors.equipmentIds?.message || 'Error') }
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="shipmentPhoto" className="mb-3">
          <Form.Label>{ t('Photo of the equipment all together') }</Form.Label>
          <PhotoInputField control={ control } name="shipmentPhoto" />
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

export default BorrowFormQr;
