import { useTranslation } from 'react-i18next'

import { useForm, Controller } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import { Camera } from 'react-bootstrap-icons'

import {
  useUpdateEventWithPhotos,
} from 'client/src/queries'

import { validImageFileVld, PhotoInputField } from './FormFields'
import CreateProgressModal from './CreateProgressModal'
import LocationPicker from './LocationPicker';
import ExamplePhoto from './ExamplePhoto';

import recoveryExampleBefore from '../assets/recoveryExampleBefore.jpg'
import recoveryExampleNameplate from '../assets/recoveryExampleNameplate.jpg'
import recoveryExampleSystem from '../assets/recoveryExampleSystem.jpg'
import recoveryExampleAfter from '../assets/recoveryExampleAfter.jpg'
import recoveryExampleCylinder from '../assets/recoveryExampleCylinder.jpg'

const CreateRecoveryVld = z.object({
  technicianName: z.string().min(5, 'Name is required'),
  recoveryAt: z.date({
    errorMap: (_issue, _ctx) => {
      return {message: 'Date is required'}
    }}),
  locationId: z.string({required_error:'Location is required'}).min(5, 'Location is required'),
  recoveryReason: z.string().min(5, 'Recovery reason is required'),
  reportedGas: z.string().min(3, 'Gas type is required'),

  weightBeforePhoto: validImageFileVld,
  nameplatePhoto: validImageFileVld,
  systemPhoto: validImageFileVld,
  weightAfterPhoto: validImageFileVld,
  cylinderCodePhoto: validImageFileVld,

  agreement: z.boolean().refine((val) => val===true, 'You must agree in order to continue'),
})

type CreateRecoveryT = z.infer<typeof CreateRecoveryVld>

function RecoveryForm() {
  const { t } = useTranslation()

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos()

  const {
    register, control, handleSubmit, formState: { errors }, reset: resetForm,
  } = useForm<CreateRecoveryT>({
    resolver: zodResolver(CreateRecoveryVld),
  })

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} />
    <Row><Col className="mx-auto mb-5" md="8" lg="6">
      <h2>{ t('Recovery') }</h2>

      <p>
        { t('Please fill out this form to record a gas recovery') }
      </p>
      <p>
        { t('⚠️ If documentation is late or does not meet requirements, your incentive payment will be reduced or voided.') }
      </p>
      <Form onSubmit={ handleSubmit((data) => mutate.mutate({ event: data, type: 'recovery' })) }>
        <h3>1. Basic Information</h3>
        <Card className="border border-dark rounded p-2 mb-3">
        <Form.Group controlId="technicianName" className="mb-3">
          <Form.Label>
            { t('Name of the person performing the recovery') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter name') }
            {...register('technicianName')}
            isInvalid={ 'technicianName' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.technicianName?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="locationId">
            { t('Location where the recovery was performed') }
          </Form.Label>
          <Controller
            control={ control }
            name="locationId"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => (<>
                <LocationPicker
                  id="locationId"
                  placeholder={ t('Select location...') }
                  clearButton={ true }
                  allowNew={true}
                  onBlur={ onBlur }
                  isInvalid={ invalid }
                  selected={ value ? [value] : [] }
                  onChange={ (selected: string[]) => {onChange(selected[0])}}
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>)
            }
          />
        </Form.Group>

        <Form.Group controlId="date" className="mb-3">
          <Form.Label>{ t('Date the recovery was performed') }</Form.Label>
          <Form.Control
            type="date"
            max={ (new Date()).toISOString().split('T')[0] }
            {...register('recoveryAt', { valueAsDate: true })}
            isInvalid={ 'recoveryAt' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.recoveryAt?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="recoveryReason" className="mb-3">
          <Form.Label>{ t('Reason for the recovery') }</Form.Label>
          <Form.Select
            {...register('recoveryReason')}
            isInvalid={ 'recoveryReason' in errors }
          >
            <option value={ null }></option>
            <option value='unitRelocate'>{ t('AC Unit Relocation') }</option>
            <option value='unitReplace'>{ t('AC Unit Replacement') }</option>
            <option value='unitMalfunction'>{ t('AC Unit Malfunction') }</option>
            <option value='contamination'>{ t('Refrigerant Contamination') }</option>
            <option value='training'>{ t('Training') }</option>
            <option value='unitPartReplace'>{ t('AC Unit Part Replacement') }</option>
            <option value='replacement'>{ t('Refrigerant Replacement') }</option>
            <option value='heelRecovery'>{ t('Cylinder Heels') }</option>
            <option value='unknown'>{ t('Unknown') }</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            { t(errors.recoveryReason?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="reportedGas" className="mb-3">
          <Form.Label>{ t('Type of gas') }</Form.Label>
          <Form.Select
            {...register('reportedGas')}
            isInvalid={ 'reportedGas' in errors }
          >
            <option value={ null }></option>
            <option value="r134a">R134a</option>
            <option value="r22">R22</option>
            <option value="r32">R32</option>
            <option value="r404a">R404a</option>
            <option value="r407c">R407c</option>
            <option value="r410a">R410a</option>
            <option value="r507">R507</option>
            <option value="r12">R12</option>
            <option value="hc">HC</option>
            <option value="unknown">{t('Unknown')}</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            { t(errors.reportedGas?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>
        </Card>

        <h3>{t('2. Upload Photos')}</h3>
        <p>{t('Please upload all five photos according to the required standards.')}</p>
        <p>{t('⚠️ Please double-check the photo requirements and examples before taking photos. If the photo does not meet requirements, your incentive payment may be at risk.')}</p>
        <Card className="my-2">
        <Form.Group controlId="weightBeforePhoto" className="mb-3 p-2">
          <Form.Label><Camera />&nbsp;{ t('Photo of scale before recovery') }</Form.Label>
          <ExamplePhoto src={recoveryExampleBefore}></ExamplePhoto>
          <PhotoInputField control={ control } name="weightBeforePhoto" />
        </Form.Group>
        </Card>

        <Card className="my-2">
        <Form.Group controlId="nameplatePhoto" className="mb-3 p-2">
          <Form.Label><Camera />&nbsp;{ t('Nameplate photo') }</Form.Label>
          <ExamplePhoto src={recoveryExampleNameplate}></ExamplePhoto>
          <PhotoInputField control={ control } name="nameplatePhoto" />
        </Form.Group>
        </Card>

        <Card className="my-2">
        <Form.Group controlId="systemPhoto" className="mb-3 p-2">
          <Form.Label><Camera />&nbsp;{ t('System photo') }</Form.Label>
          <ExamplePhoto src={recoveryExampleSystem}></ExamplePhoto>
          <PhotoInputField control={ control } name="systemPhoto" />
        </Form.Group>
        </Card>

        <Card className="my-2">
        <Form.Group controlId="weightAfterPhoto" className="mb-3 p-2">
          <Form.Label><Camera />&nbsp;{ t('Photo of scale after recovery') }</Form.Label>
          <ExamplePhoto src={recoveryExampleAfter}></ExamplePhoto>
          <PhotoInputField control={ control } name="weightAfterPhoto" />
        </Form.Group>
        </Card>

        <Card className="my-2">
        <Form.Group controlId="cylinderCodePhoto" className="mb-3 p-2">
          <Form.Label><Camera />&nbsp;{ t('Cylinder code photo') }</Form.Label>
          <ExamplePhoto src={recoveryExampleCylinder}></ExamplePhoto>
          <PhotoInputField control={ control } name="cylinderCodePhoto" />
        </Form.Group>
        </Card>

        <h3>{t('3. Submit Data')}</h3>
        <Card className="my-2">
        <Form.Group controlId="agreement" className="mb-3 p-2">
          <p>{t('⚠️ You must agree to these terms and click "Submit" below.')}</p>
          <Form.Label><b>{ t('Statement of Agreement')}</b></Form.Label>
          <p>{t('By submitting the form, I agree with Recoolit\'s terms and conditions, including the following:')}</p>
          <ol>
            <li> { t('I have obtained approval from the AC unit owner to carry out refrigerant recovery.') } </li>
            <li> { t('The owner of the AC unit has stated that he has transferred full responsibility for the refrigerant to me.') } </li>
            <li> { t('I have the legal right to collect and sell waste refrigerant to RECOOLIT.') } </li>
            <li> { t('I will release the refrigerant into the atmosphere if I don\'t sell it to RECOOLIT.') } </li>
            <li> { t('I only give the right to sell this refrigerant recovery activity in the form of carbon credits or other emissions trading to RECOOLIT.') } </li>
            <li> { t('RECOOLIT has the right to use data and photo documentation of this activity for any purpose.') } </li>
            <li> { t('The results of the reweighing at the RECOOLIT warehouse will be the basis for incentive payments, and RECOOLIT\'s decision regarding the results of the reweighing is final and cannot be contested.') } </li>
          </ol>
          <Form.Check
            {...register('agreement')}
            type="checkbox"
            id="agreement"
            label={t('Yes, I agree')}
            feedbackType='invalid'
            feedback={ t(errors.agreement?.message || 'Error') }
            isInvalid={'agreement' in errors}
          >
          </Form.Check>
        </Form.Group>
        </Card>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

export default RecoveryForm;
