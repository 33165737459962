import NavDropdown from 'react-bootstrap/NavDropdown'
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

// load supported locales; should match the `languages` array below
import 'dayjs/locale/en'
import 'dayjs/locale/id'

import usa from '../assets/united-states.png'
import indonesia from '../assets/indonesia.png'

// add timezone support to dayjs
dayjs.extend(utc)
dayjs.extend(tz)

// configure supported languages
const iconSize = '24'
interface Language { code: string, flag: JSX.Element }

const languages: Language[] = [
  { code: 'en', flag: (<img src={usa} height={iconSize} alt="english" />) },
  { code: 'id', flag: (<img src={indonesia} height={iconSize} alt="indonesian" />) },
]

function LangPicker() {
  const { i18n } = useTranslation()

  function mkHandler(lang: Language) {
    return () => {
      void i18n.changeLanguage(lang.code)
    }
  }

  // if we didn't get a `resolvedLanguage`, force one to get set.
  // this happens because the language detector does not seem to set `resolvedLanguage`.
  // this will trigger a re-render of this component
  if (!i18n.resolvedLanguage) {
    void i18n.changeLanguage(i18n.language || languages[0].code)
  }

  // set date formatting locale globally
  if (i18n.resolvedLanguage) {
    dayjs.locale(i18n.resolvedLanguage)
  }

  const curLang = languages.find(l => l.code === i18n.resolvedLanguage) || languages[0]

  return (
    <NavDropdown title={curLang.flag} id="language-picker" data-testid="language-picker">
      { languages.map((lang) => (
        <NavDropdown.Item onClick={mkHandler(lang)} key={lang.code} >
          <span>{ lang.flag } { lang.code }</span>
        </NavDropdown.Item>
      )) }
    </NavDropdown>
  )
}

export default LangPicker;
