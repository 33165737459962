import Spinner from 'react-bootstrap/Spinner'
import Stack from 'react-bootstrap/Stack'

import { useErrorLog } from 'client/src/queries'

import DateStr from './DateStr'

function ErrorLog() {
  const { isLoading, data } = useErrorLog()

  if (isLoading) {
    return <Spinner variant='primary' animation='border' />
  }

  return (
    <Stack direction="vertical" gap={ 2 }>
      <h2>Error Logs</h2>

      { data?.errorLogs.map(log => (
        <Stack direction="horizontal" gap={ 2 }>
          <ul className="mb-auto">
            <li><b>Process:</b> {log.processName}</li>
            <li><b>Created:</b> <DateStr date={log.createdAt} /></li>
            <li><b>Updated:</b> <DateStr date={log.updatedAt} /></li>
          </ul>

          <pre className="bg-light border border-danger p-2">{ log.errors }</pre>
        </Stack>
      ))}
    </Stack>
  )
}

export default ErrorLog
