import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'

import type { GasRecovery } from '../../../api-rec/src/db/gasRecoveries'
import type { EquipmentBorrow } from '../../../api-rec/src/db/equipmentBorrows'
import type { Technician } from '../../../api-rec/src/db/technicians'
import type { Shipment } from '../../../api-rec/src/db/shipments'

import { useUpdateEvent, useUpdateEventWithPhotos, useUpdateTechnician } from 'client/src/queries'
import type { UseOpsTodoT } from 'client/src/queries'

import { validImageFileVld, PhotoInputField } from './FormFields'
import EventImage from './EventImage';
import { addAlert } from '../state/alerts'

type OpsTaskType = keyof UseOpsTodoT['tasks']
type OpsTask = UseOpsTodoT['tasks'][OpsTaskType][number]
type TaskCardP = { task: OpsTask }

type MutationResult = ReturnType<typeof useUpdateEvent> | ReturnType<typeof useUpdateTechnician>
function viaMutation(mutate: MutationResult, type: string, alertDesc?: string) {
  return (event: Record<string, unknown>) => mutate.mutate({event, type}, {
    onSuccess: () => {
      if (alertDesc) {
          addAlert({
            level: 'ok',
            message: `${alertDesc} completed successfully`,
            autoClose: true,
          })
        }
      },
    onError: (err) => {
      if (alertDesc) {
        const errMsg = err instanceof Error ? err.message : String(err)
        addAlert({
          level: 'error',
          message: `${alertDesc} failed with error ${ errMsg }`,
          autoClose: true,
        })
      }
    },
  })
}

type SubmitButtonProps = Parameters<typeof Button>[0] & {
  loading: boolean,
  text: string,
}

function SubmitButton({ loading, text, ...rest }: SubmitButtonProps) {
  return (
    <Button {...rest} type="submit" disabled={ loading }>
      { loading ?
        <><Spinner animation="border" variant="primary" /> Updating... </> : text
      }
    </Button>
  )
}

const UpdateRecoveryVld = z.object({
  id: z.string(),
  eqStickerCode: z.string().min(5, 'Valid code required'),
  weightBefore: z.number().nullable(),
  weightBeforeMissing: z.boolean(),
  weightAfter: z.number().nullable(),
  weightAfterMissing: z.boolean(),
  externalDeviceId: z.string().nullable(),
  externalDeviceIdSource: z.string().nullable(),
  externalDeviceIdMissing: z.boolean(),
  canSell: z.boolean(),
  notes: z.string().nullable(),
})

const recoveryEquipmentVld = UpdateRecoveryVld.pick({
  id: true,
  eqStickerCode: true,
})
type recoveryEquipmentT = z.infer<typeof recoveryEquipmentVld>

function RecoveryEquipment({ task }: TaskCardP) {
  const { t } = useTranslation()
  const mutate = useUpdateEvent()

  const { register, handleSubmit, formState: { errors } } = useForm<recoveryEquipmentT>({
    resolver: zodResolver(recoveryEquipmentVld),
  })

  const event = task.event as GasRecovery
  register('id', { value: event.id })

  return (
    <Card className="col-12 col-md-4 col-lg-3" key={ `${task.id}-rE` }>
      <Card.Body>
        <Card.Title>{ t('Set Equipment Code') }</Card.Title>
        <EventImage id={ event.cylinderCodePhoto } label="Cylinder Code Photo" />
        <Form
          onSubmit={ handleSubmit(viaMutation(mutate, 'recovery', 'Setting recovery cylinder ID')) }
        >
          <Form.Group controlId="eqStickerCode" className="mb-3">
            <Form.Label> { t('Equipment Code') } </Form.Label>
            <Form.Control
              {...register(
                'eqStickerCode',
                { value: '', required: true},
              )}
              type="text"
              placeholder={ t('Enter equipment code') }
              isInvalid={ 'eqStickerCode' in errors }
            />
            <Form.Control.Feedback type="invalid">
              { t(errors.eqStickerCode?.message || 'Error') }
            </Form.Control.Feedback>
          </Form.Group>

          <SubmitButton loading={ mutate.isLoading } text={ t('Set Equipment Code') } />
        </Form>
      </Card.Body>
    </Card>
  )
}

const UpdateBorrowVld = z.object({
  id: z.string(),
  eqStickerCode: z.string().min(5, 'Valid code required'),
})

type updateBorrowT = z.infer<typeof UpdateBorrowVld>

function BorrowEquipment({ task }: TaskCardP) {
  const { t } = useTranslation()
  const mutate = useUpdateEvent()

  const { register, handleSubmit, formState: { errors } } = useForm<updateBorrowT>({
    resolver: zodResolver(recoveryEquipmentVld),
  })

  const event = task.event as EquipmentBorrow
  register('id', { value: event.id })

  return (
    <Card className="col-12 col-md-4 col-lg-3" key={ `${task.id}-eB` }>
      <Card.Body>
        <Card.Title>{ t('Set Equipment Code') }</Card.Title>
        <EventImage id={ event.borrowPhoto } label="Equipment Code Photo" />
        <Form
          onSubmit={ handleSubmit(viaMutation(mutate, 'borrow', 'Setting borrow equipment ID')) }
        >
          <Form.Group controlId="eqStickerCode" className="mb-3">
            <Form.Label> { t('Equipment Code') } </Form.Label>
            <Form.Control
              {...register(
                'eqStickerCode',
                { value: '', required: true},
              )}
              type="text"
              placeholder={ t('Enter equipment code') }
              isInvalid={ 'eqStickerCode' in errors }
            />
            <Form.Control.Feedback type="invalid">
              { t(errors.eqStickerCode?.message || 'Error') }
            </Form.Control.Feedback>
          </Form.Group>

          <SubmitButton loading={ mutate.isLoading } text={ t('Set Equipment Code') } />
        </Form>
      </Card.Body>
    </Card>
  )
}

const ShipmentHandoverPhotoVld = z.object({
  id: z.string(),
  handoverPhoto: validImageFileVld.optional(),
  handoverPhotoMissing: z.boolean(),
}).refine(
  (val) => val.handoverPhotoMissing || val.handoverPhoto,
  {message: 'cannot mark missing if you upload a photo and vice versa', path:['handoverPhoto']})


type ShipmentHandoverT = z.infer<typeof ShipmentHandoverPhotoVld>

function ShipmentHandover({ task }: TaskCardP) {
  const { t } = useTranslation()

  const event = task.event as Shipment
  const { mutate } = useUpdateEventWithPhotos({specialEvents: 'shipment'})
  const { register, setValue, control, handleSubmit, formState: { errors } } = useForm<ShipmentHandoverT>({
    resolver: zodResolver(ShipmentHandoverPhotoVld),
    defaultValues: {'id': event.id, 'handoverPhotoMissing': null},
  })

  register('handoverPhotoMissing')

  const setHandoverPhotoMissing = (val: boolean) => () => {
    setValue('handoverPhotoMissing', val, { shouldDirty: true, shouldValidate: true })
    return true
  }
  register('id')

  return (
    <Card className="col-12 col-md-4 col-lg-3" key={ `${task.id}-s` }>
      <Card.Body>
        <Card.Title>{ t('Upload Handover Photo') }</Card.Title>
        <Form
          onSubmit={ handleSubmit(viaMutation(mutate, 'shipment', 'Setting shipment handover photo')) }
        >
          <Form.Group controlId="handoverPhoto" className="mb-3">
            <Form.Label> { t('Handover Photo') } </Form.Label>
            <PhotoInputField control={control} name='handoverPhoto' />
            <Form.Control.Feedback type="invalid">
              { t(errors.handoverPhoto?.message || 'Error') }
            </Form.Control.Feedback>
          </Form.Group>
          <SubmitButton
            onClick={setHandoverPhotoMissing(false)}
            loading={ mutate.isLoading }
            text={ t('Upload') }
          />
          <SubmitButton
            onClick={setHandoverPhotoMissing(true)}
            loading={ mutate.isLoading }
            text={ t('Mark missing') }
            variant='danger'
          />
        </Form>
      </Card.Body>
    </Card>
  )
}

function nullableNumber(v: string | null):(number | null) {
  return ((v === '') || (v === null)) ? null : Number(v)
}

function weightReviewIsConsistent(missingFlag: boolean, measurement: number | null ): boolean {
  // if we explicitly mark the weight as missing, the weight measurement should be null
  // conversely, if there's a non-null weight value, we should not mark it as missing.
  // applies to both weightBefore and weightAfter
  return missingFlag === (measurement === null)
}

const recoveryWeightBeforeVld = UpdateRecoveryVld.pick({
  id: true,
  weightBefore: true,
  weightBeforeMissing: true,
}).refine(
  // if we explicitly mark the weight as missing, the weight value should be null
  // conversely, if there's a non-null weight value, we should not mark it as missing.
  (val) => weightReviewIsConsistent(val.weightBeforeMissing, val.weightBefore),
  {message: 'weight must be missing to mark missing, and vice versa', path: ['weightBefore']}
)
type recoveryWeightBeforeT = z.infer<typeof recoveryWeightBeforeVld>

function RecoveryWeightBefore({ task }: TaskCardP) {
  const { t } = useTranslation()
  const mutate = useUpdateEvent()

  const event = task.event as GasRecovery
  const { register, setValue, handleSubmit, formState: { errors } } = useForm<recoveryWeightBeforeT>({
    resolver: zodResolver(recoveryWeightBeforeVld),
    defaultValues: {
        'id': event.id,
        'weightBefore': event.weightBefore,
        'weightBeforeMissing': null,
  }})

  register('id')
  register('weightBeforeMissing')

  const setWeightBeforeMissing = (val: boolean) => () => {
    setValue('weightBeforeMissing', val, { shouldDirty: true, shouldValidate: true })
    return true
  }

  return (
    <Card className="col-12 col-md-4 col-lg-3" key={ `${task.id}-rWB` }>
      <Card.Body>
        <Card.Title>{ t('Set Weight Before') }</Card.Title>
        <EventImage id={ event.weightBeforePhoto } label="Weight Before Photo" />
        <Form onSubmit={handleSubmit(viaMutation(mutate, 'recovery', 'Setting cylinder pre-recovery weight'))}>
          <Form.Group controlId="weightBefore" className="mb-3">
            <Form.Label> { t('Weight Before') } </Form.Label>
            <Form.Control
              {...register(
                'weightBefore',
                {
                // ugly, but required to make this nullable
                // https://github.com/react-hook-form/react-hook-form/discussions/6980#discussioncomment-1785009
                setValueAs: nullableNumber,
                },
              )}
              type="number"
              step="0.01"
              placeholder={ t('Enter weight before') }
              isInvalid={ 'weightBefore' in errors }
            />
            <Form.Control.Feedback type="invalid">
              { t(errors.weightBefore?.message || 'Error') }
            </Form.Control.Feedback>
          </Form.Group>

          <SubmitButton
            onClick={ setWeightBeforeMissing(false) }
            loading={ mutate.isLoading }
            text={ t('Set Weight Before') } />
          <SubmitButton
            onClick={ setWeightBeforeMissing(true) }
            loading={ mutate.isLoading }
            variant="danger"
            text={ t('Mark Missing') } />
        </Form>
      </Card.Body>
    </Card>
  )
}

const recoveryWeightAfterVld = UpdateRecoveryVld.pick({
  id: true,
  weightAfter: true,
  weightAfterMissing: true,
}).refine(
  (val) => weightReviewIsConsistent(val.weightAfterMissing, val.weightAfter),
  {message: 'weight must be missing to mark missing, and vice versa', path: ['weightAfter']}
)
type recoveryWeightAfterT = z.infer<typeof recoveryWeightAfterVld>

function RecoveryWeightAfter({ task }: TaskCardP) {
  const { t } = useTranslation()
  const mutate = useUpdateEvent()

  const event = task.event as GasRecovery
  const { register, setValue, handleSubmit, formState: { errors } } = useForm<recoveryWeightAfterT>({
    resolver: zodResolver(recoveryWeightAfterVld),
    defaultValues: {
        'id': event.id,
        'weightAfter': event.weightAfter,
        'weightAfterMissing': null,
  }})

  register('id')
  register('weightAfterMissing')

  const setWeightAfterMissing = (val: boolean) => () => {
    setValue('weightAfterMissing', val, { shouldDirty: true, shouldValidate: true })
    return true
  }

  return (
    <Card className="col-12 col-md-4 col-lg-3" key={ `${task.id}-rWA` }>
      <Card.Body>
        <Card.Title>{ t('Set Weight After') }</Card.Title>
        <EventImage id={ event.weightAfterPhoto } label="Weight After Photo" />
        <Form onSubmit={handleSubmit(viaMutation(mutate, 'recovery', 'Setting cylinder post-recovery weight'))}>
          <Form.Group controlId="weightAfter" className="mb-3">
            <Form.Label> { t('Weight After') } </Form.Label>
            <Form.Control
              {...register(
                'weightAfter',
                {
                // ugly, but required to make this nullable
                // https://github.com/react-hook-form/react-hook-form/discussions/6980#discussioncomment-1785009
                setValueAs: nullableNumber,
                },
              )}
              type="number"
              step="0.01"
              placeholder={ t('Enter weight after') }
              isInvalid={ 'weightAfter' in errors }
            />
            <Form.Control.Feedback type="invalid">
              { t(errors.weightAfter?.message || 'Error') }
            </Form.Control.Feedback>
          </Form.Group>

          <SubmitButton
            onClick={ setWeightAfterMissing(false) }
            loading={ mutate.isLoading }
            text={ t('Set Weight After') } />
          <SubmitButton
            onClick={ setWeightAfterMissing(true) }
            loading={ mutate.isLoading }
            variant="danger"
            text={ t('Mark Missing') } />
        </Form>
      </Card.Body>
    </Card>
  )
}

const externalDeviceIdVld = UpdateRecoveryVld.pick({
  id: true,
  externalDeviceId: true,
  externalDeviceIdSource: true,
  externalDeviceIdMissing: true,
})
type externalDeviceIdT = z.infer<typeof externalDeviceIdVld>

function ExternalDeviceId({ task }: TaskCardP) {
  const { t } = useTranslation()
  const mutate = useUpdateEvent()

  const event = task.event as GasRecovery
  const { register, setValue, handleSubmit, formState: { errors } } = useForm<externalDeviceIdT>({
    resolver: zodResolver(externalDeviceIdVld),
    defaultValues: {
      'externalDeviceId': event.externalDeviceId,
      'externalDeviceIdSource': event.externalDeviceIdSource,
      externalDeviceIdMissing: null,
  }})

  register('id', { value: event.id })
  register('externalDeviceIdMissing')

  const setExternalIdMissing = (val: boolean) => () => {
    setValue('externalDeviceIdMissing', val, { shouldDirty: true, shouldValidate: true })
    return true
  }


  return (
    <Card className="col-12 col-md-4 col-lg-3" key={ `${task.id}-eDI` }>
      <Card.Body>
        <Card.Title>{ t('Set External Device Id') }</Card.Title>
        <EventImage id={ event.nameplatePhoto } label="Nameplate Photo" />
        <Form onSubmit={handleSubmit(viaMutation(mutate, 'recovery', 'Setting recovery device ID'))}>
          <Form.Group controlId="externalDeviceId" className="mb-3">
            <Form.Label> { t('Device Id') } </Form.Label>
            <Form.Control
              {...register(
                'externalDeviceId',
              )}
              type="text"
              placeholder={ t('Enter external id') }
              isInvalid={ 'externalDeviceId' in errors }
            />
            <Form.Label> { t('Device Id Source') } </Form.Label>
            <Form.Control
              {...register(
                'externalDeviceIdSource',
              )}
              type="text"
              placeholder={ t('eg "nameplate"') }
              isInvalid={ 'externalDeviceIdSource' in errors }
            />
            <Form.Control.Feedback type="invalid">
              { t(errors.externalDeviceId?.message || 'Error') }
            </Form.Control.Feedback>
          </Form.Group>

          <SubmitButton
            onClick={ setExternalIdMissing(false) }
            loading={ mutate.isLoading }
            text={ t('Set device id') } />
          <SubmitButton
            onClick={ setExternalIdMissing(true) }
            loading={ mutate.isLoading }
            variant="danger"
            text={ t('Mark Missing') } />
        </Form>
      </Card.Body>
    </Card>
  )
}

const recoverySetCanSellVld = UpdateRecoveryVld.pick({
  id: true,
  canSell: true,
  notes: true,
})
type recoverySetCanSellT = z.infer<typeof recoverySetCanSellVld>

function RecoverySetCanSell({ task }: TaskCardP) {
  const { t } = useTranslation()
  const mutate = useUpdateEvent()

  const { register, setValue, handleSubmit, formState: { errors } } = useForm<recoverySetCanSellT>({
    resolver: zodResolver(recoverySetCanSellVld),
  })

  const event = task.event as GasRecovery
  register('id', { value: event.id })
  register('canSell')
  register('notes')

  const setCanSell = (val: boolean) => () => {
    setValue('canSell', val, { shouldDirty: true, shouldValidate: true })
    return true
  }

  const showErrors = isEmpty(errors) ? '' : 'd-block'

  return (
    <Card className="col-12 col-lg-3" key={ `${task.id}-rSS` }>
      <Card.Body>
        <Card.Title>{ t('Mark CanSell') }</Card.Title>
        <Form onSubmit={handleSubmit(viaMutation(mutate, 'recovery', 'Setting recovery sellable status'))}>
          <div className={ `invalid-feedback ${ showErrors }` }>
            { Object.entries(errors).map(([field, info]) => (
              <span key={ field }>{ t(info.message || 'Invalid field', { field }) }</span>
            ))}
          </div>
          <Form.Group controlId="notes" className="mb-3">
            <Form.Label> { t('Notes') } </Form.Label>
            <Form.Control
              {...register(
                'notes',
                { required: false},
              )}
              type="text"
              placeholder={ t('Enter notes (required if not CanSell)') }
              isInvalid={ 'notes' in errors }
            />
            <Form.Control.Feedback type="invalid">
              { t(errors.notes?.message || 'Error') }
            </Form.Control.Feedback>
          </Form.Group>

          <SubmitButton
            onClick={ setCanSell(true) }
            loading={ mutate.isLoading }
            variant="primary"
            text={ t('CanSell') } />
          <SubmitButton
            onClick={ setCanSell(false) }
            loading={ mutate.isLoading }
            variant="danger"
            text={ t('Not CanSell') } />
        </Form>
      </Card.Body>
    </Card>
  )
}


const updateTechnicianVld = z.object({
  id: z.string(),
  name: z.string().min(5, 'Name is required'),
  phone: z.string().min(7, 'Too short for a phone number').regex(/^[0-9+-.() ]+$/, 'Not a valid phone number'),
  isEmployed: z.boolean().optional(),
  company: z.string().or(z.null()).optional(),
  companyContact: z.string().or(z.null()).optional(),
  companyLocation: z.string().or(z.null()).optional(),
  certification: z.string().or(z.null()).optional(),
  isValid: z.boolean(),
}).superRefine((user, ctx) => {
  if (!user.isValid || !user.isEmployed) {
    return true
  }
  for (const key of ['company', 'companyLocation', 'companyContact']) {
    const value = user[key as keyof typeof user]
    if (typeof value !== 'string' || value.length < 5) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `${key} is required for non-freelance-users`,
        path: [key],
      })
    }
  }
})

type ValidateTechnicianT = z.infer<typeof updateTechnicianVld>

function TechnicianValidate({ task }: TaskCardP) {
  const { t } = useTranslation()
  const mutate = useUpdateTechnician()
  const event = task.event as Technician
  const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm<ValidateTechnicianT>({
    resolver: zodResolver(updateTechnicianVld),
    defaultValues: event,
  })

  const isEmployed = watch('isEmployed')


  const setIsValid = (val: boolean) => () => {
    setValue('isValid', val, { shouldDirty: true, shouldValidate: true })
    return true
  }

  return (
    <Card className="col-12 col-lg-3" key={ `${task.id}-rSS` }>
      <Card.Body>
        <Card.Title>{ t('Validate Technician') }</Card.Title>
        <Form onSubmit={handleSubmit(viaMutation(mutate, 'technician', 'Setting technician valid status'))}>
          <EventImage id={ event?.idPhoto } label="KTP Photo" />
          <Form.Group controlId="name" className="mb-3">
            <Form.Label> { t('Technician Name') } </Form.Label>
            <Form.Control
              {...register(
                'name',
                { required: true },
              )}
              type="text"
              isInvalid={ 'name' in errors }
            />
            <Form.Control.Feedback type="invalid">
              { t(errors.name?.message || 'Error') }
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="phone" className="mb-3">
            <Form.Label> { t('Phone Number') } </Form.Label>
            <Form.Control
              {...register(
                'phone',
                { required: true},
              )}
              type="text"
              isInvalid={ 'phone' in errors }
            />
            <Form.Control.Feedback type="invalid">
              { t(errors.phone?.message || 'Error') }
            </Form.Control.Feedback>
          </Form.Group>
        <Form.Group controlId="isEmployed" className="mb-3">
          <Form.Label>
            { t('Are you employed by a company (PT or CV)?') }
          </Form.Label>
          <Form.Check
            type="checkbox"
            label={ t('Yes') }
            {...register('isEmployed')}
            isInvalid={ 'isEmployed' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.isEmployed?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="company" className="mb-3">
          <Form.Label>
            { t('Company Name') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter company name') }
            {...register('company')}
            isInvalid={ 'company' in errors }
            disabled={ !isEmployed }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.company?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="companyContact" className="mb-3">
          <Form.Label>
            { t('Company Contact information (email or WA)') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter company contact') }
            {...register('companyContact')}
            isInvalid={ 'companyContact' in errors }
            disabled={ !isEmployed }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.companyContact?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="companyLocation" className="mb-3">
          <Form.Label>
            { t('Company Address') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter company address') }
            {...register('companyLocation')}
            isInvalid={ 'companyLocation' in errors }
            disabled={ !isEmployed }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.companyLocation?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="certification" className="mb-3">
          <Form.Label>
            { t('Certification') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter certification (if any)') }
            {...register('certification')}
            isInvalid={ 'certification' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.certification?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

          <SubmitButton
            onClick={ setIsValid(true) }
            loading={ mutate.isLoading }
            variant="primary"
            text={ t('Valid') } />
          <SubmitButton
            onClick={ setIsValid(false) }
            loading={ mutate.isLoading }
            variant="danger"
            text={ t('Not Valid') } />
        </Form>
      </Card.Body>
    </Card>
  )
}

function TaskCards({task}: TaskCardP) {
  const cards: ReactNode[] = []

  if (task.type === 'validateRecovery') {
    const recovery = task.event as GasRecovery
    if (recovery) {
      if (!recovery.equipmentId) {
        cards.push(<RecoveryEquipment task={task} key="re" />)
      }

      if (recovery.weightBeforeMissing === null) {
        cards.push(<RecoveryWeightBefore task={task} key="rwb" />)
      }

      if (recovery.weightAfterMissing === null) {
        cards.push(<RecoveryWeightAfter task={task} key="rwa" />)
      }

      if (recovery.externalDeviceIdMissing === null) {
        cards.push(<ExternalDeviceId task={task} key="edi" />)
      }

      if (recovery.canSell === null) {
        cards.push(<RecoverySetCanSell task={task} key="rss" />)
      }
    }
  } else if (task.type === 'vacuumEquipment') {
    // nothing for now!
  } else if (task.type === 'validateTechnician') {
    // TODO(Louis): only if the validation is missing, in case of out of sync
     cards.push(<TechnicianValidate task={task} key="tch" />)
  } else if (task.type === 'validateBorrow') {
     cards.push(<BorrowEquipment task={task} key="brw" />)
  } else if (task.type === 'validateShipment') {
    // for now there's no other type of shipment card
     cards.push(<ShipmentHandover task={task} key="" />)
  } else {
    console.error('unexpected task type')
  }

  return (
    <>{ cards }</>
  )
}

export {
  TaskCards,
  SubmitButton,
}
