import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import type { Icon } from 'react-bootstrap-icons'

import { UserRecoveries } from './UserRecoveries'
import UserQr from './UserQr'
import {
  PersonPlus,
  Youtube,
  Wrench,
  BootstrapReboot,
  Shuffle,
  XDiamond,
  Check2Circle,
  Tornado,
  ExclamationTriangle,
  InfoCircle,
  Book,
  Truck,
  Fingerprint,
  CartPlus,
  BuildingAdd,
  Mortarboard,
} from 'react-bootstrap-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';

import { useSession } from 'client/src/queries'
import WhatsappButton from './WhatsappButton'

export const Weight = () => <FontAwesomeIcon icon={faBalanceScale} />

type LinktreeButton = {
  icon: Icon,
  text: string,
  show: boolean,
  to: string,
}
type LinktreeSectionP = {
  sectionName: string,
  buttons: Array<Partial<LinktreeButton>>,
}

function LinktreeSection(params: LinktreeSectionP) {
  const navigate = useNavigate()
  const mkNavigate = (to: string) => {
    return () => {
      if (to) {
        if (to.startsWith('/')) {
          navigate(to)
        } else {
          window.location.assign(to)
        }
      } else {
        return false
      }
    }
  }
  return (
    <Row className="mx-auto mb-5">
      <h2 className="mx-auto">{params.sectionName}</h2>
        <div className="d-grid gap-2">
          { params.buttons.map((button, idx) => {
            if (!(button.show && button.to)) return null;

            return <Button
              variant="primary"
              size="lg"
              key={idx}
              onClick={ mkNavigate(button.to) }
              disabled={ !button.to }
            >
              <Row>
                <Col xs={1}> <button.icon /> </Col>
                <Col className="mx-auto"> { button.text } </Col>
              </Row>
            </Button>
          }) }
        </div>
    </Row>
  )
}

function Linktree() {
  const { t } = useTranslation()

  const { data } = useSession()
  const currentUser = data?.user
  const isOpsPerson = currentUser?.accessOps
  const isField = currentUser?.accessField
  const isAccessApp = currentUser?.accessApp


  const buttons = [
    {
      icon: BootstrapReboot,
      text: t('New Recovery'),
      to: '/forms/recovery',
      show: !!isAccessApp,
    },
    {
      icon: PersonPlus,
      text: t('Login/Register'),
      show: !currentUser,
      to: '/forms/user',
    },
    {
      icon: Wrench,
      text: t('Borrow Equipment'),
      show: !!isField,
      to: '/forms/borrow',
    },
    {
      icon: CartPlus,
      text: t('New Equipment'),
      show: isOpsPerson,
      to: '/forms/equipment',
    },
    {
      icon: BuildingAdd,
      text: t('New Project Location'),
      show: isOpsPerson,
      to: '/forms/location',
    },
    {
      icon: Truck,
      text: t('Move Equipment'),
      show: isOpsPerson,
      to: '/forms/move',
    },
    {
      icon: Shuffle,
      text: t('Gas Transfer'),
      to: '/forms/transfer',
      show: isOpsPerson,
    },
    {
      icon: Tornado,
      text: t('Vacuum'),
      to: '/forms/vacuum',
      show: isOpsPerson,
    },
    {
      icon: Fingerprint,
      text: t('Identification'),
      to: '/forms/ident',
      show: isOpsPerson,
    },
    {
      icon: Check2Circle,
      text: t('Quality Control'),
      to: '/forms/qc',
      show: isOpsPerson,
    },
    {
      icon: XDiamond,
      text: t('Refrigerant Destruction'),
      show: isOpsPerson,
    },
    {
      icon: Weight,
      text: t('Weight'),
      to: 'forms/weight',
      show: isOpsPerson,
    },
  ]

  const infoButtons = [
    {
      icon: ExclamationTriangle,
      text: t('Maximum cylinder capacity'),
      show: true,
      to: 'https://drive.google.com/file/d/1W6a-U8lOdrdB2PFtihPLNCVdPmISRbPj/view',
    },
    {
      icon: InfoCircle,
      text: t('Statement Letter'),
      show: true,
      to: 'https://drive.google.com/file/d/1LlhJM6wKtEROI1Mmf9HK3ksHtA1VqpzE/view',
    },
    {
      icon: Book,
      text: t('Equipment Manuals'),
      show: true,
      to: 'https://drive.google.com/drive/folders/11j7p1QHXeDDOVkPPc5csg4kR_0eEZc6_',
    },
    {
      icon: Youtube,
      text: t('Video Tutorial: Registration'),
      show: true,
      to: 'https://youtu.be/PD0dCkIezfU',
    },
    {
      icon: Youtube,
      text: t('Video Tutorial: Recovery Documentation'),
      show: true,
      to: 'https://youtu.be/E_gEckU0vNY',
    },
    {
      icon: Mortarboard,
      text: t('Recovery Documentation Quiz'),
      show: true,
      to: 'https://forms.gle/2RAZa5XBoWyVyNbb9',
    },
  ]

  return (
    <Row>
      <Col xs={2} className="d-none d-md-block"></Col>
      <Col className="col-md-8 col-12">
        { currentUser ? <UserQr userId={currentUser?.id} /> : null }
        { isAccessApp ? <UserRecoveries /> : null }
        <LinktreeSection sectionName={ t('Forms')} buttons={buttons}/>
        <LinktreeSection sectionName={ t('Information') } buttons={infoButtons}/>
        <Row>
          <h2>{t('Need Help?')}</h2>
          <div className="d-grid gap-2">
            <WhatsappButton buttonText={t('Chat us on WhatsApp')}/></div>
        </Row>
      </Col>
    </Row>
  );
}

export default Linktree;
