import { useTranslation } from 'react-i18next';

import { ReactNode, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import { useEquipmentLocations, useUpdateLocation } from 'client/src/queries'
import { findBestMatch } from '../lib/stringSimilarity'
import type { BestMatch } from '../lib/stringSimilarity'

const CreateLocationVld = z.object({
  name: z.string().min(5, 'Location name is required'),
  address: z.string().min(5, 'Address is required'),
  chargePerson: z.string().min(5, 'PIC is required'),
  gmapLocation: z.string().url('That doesn\'t look like a URL').optional(),
})

type CreateLocationT = z.infer<typeof CreateLocationVld>

function LocationForm() {
  const { t } = useTranslation()

  const { isLoading, data } = useEquipmentLocations()
  const locationNames = data?.locations.map((l) => l.name) || []

  const { mutate, isSuccess } = useUpdateLocation()

  const {
    register, setError, watch, handleSubmit, formState: { errors },
  } = useForm<CreateLocationT>({
    resolver: zodResolver(CreateLocationVld),
  })

  let uniqueWarning: ReactNode | null
  let bestMatch: BestMatch['ratings'] = []

  useEffect(() => {
    if (bestMatch.length > 0 && bestMatch[0].rating === 1) {
      setError('name', {type: 'unique', message: 'That project name already exists'})
    }}, [bestMatch, setError])

  const currentName = watch('name')
  if (!isLoading && currentName) {
    bestMatch = findBestMatch(currentName, locationNames).ratings.sort(
      (a, b) => b.rating - a.rating
    ).slice(0, 5)
    uniqueWarning = (
      <Form.Text>Are you sure it's new? Check our most similar project sites...
        <ul>
          {bestMatch.map((m, idx) => <li key={idx}>{`${m.target} (${(m.rating*100).toFixed(0)}% similar)`}</li>)}
        </ul>
      </Form.Text>
    )
  } else uniqueWarning = null


  if (isSuccess) {
    return <><p></p><div className='card card-success'><div className="card-body">
      Thanks!
    </div></div></>
  }

  return (<>
    <Row><Col className="mx-auto" md="8" lg="6">
      <h2>{ t('New Location/Project') }</h2>

      <Form onSubmit={ handleSubmit((data) => mutate({ update: data})) }>
        <Form.Group controlId="name" className="mb-3">
          <Form.Label>
            { t('Location name') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Location name') }
            {...register('name')}
            isInvalid={ 'name' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.name?.message || 'Error') }
          </Form.Control.Feedback>
          <Form.Text>
            {uniqueWarning}
          </Form.Text>

        </Form.Group>

        <Form.Group controlId="address" className="mb-3">
          <Form.Label>
            { t('Location address') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter address') }
            {...register('address')}
            isInvalid={ 'address' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.address?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="chargePerson" className="mb-3">
          <Form.Label>
            { t('Location PIC and contact info') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter location PIC') }
            {...register('chargePerson')}
            isInvalid={ 'chargePerson' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.chargePerson?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="gmapLocation" className="mb-3">
          <Form.Label>
            { t('Google maps link to the site') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter url') }
            {...register('gmapLocation')}
            isInvalid={ 'gmapLocation' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.gmapLocation?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row></>)
}

export default LocationForm;
