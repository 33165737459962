import Accordion from 'react-bootstrap/Accordion'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { QrCode } from 'react-bootstrap-icons'

import QrEncoder from './QrEncoder'

function UserQr({ userId }: {userId: string}) {
  const body = (<>
      <Accordion className="my-3 highlight-border">
        <Accordion.Header>My QR Code &nbsp;<QrCode/></Accordion.Header>
        <Accordion.Body>
          <Col xs={{span: 8, offset: 2}} lg={{span: 4, offset: 4}}>
            <Card>
              <QrEncoder text={`https://app.recoolit.com/user/${userId}`} />
            </Card>
          </Col>
        </Accordion.Body>
      </Accordion>
    </>)
  return (
    <Row className='mx-auto-mb-5'>
      {body}
    </Row>
  )
}

export default UserQr
