import { useTranslation } from 'react-i18next'
import { useUser } from 'client/src/queries'
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import DateStr from './DateStr'
import EventImage from './EventImage'

import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import CreateProgressModal from './CreateProgressModal'
import { validImageFileVld, PhotoInputField } from './FormFields'

import {
  useUpdateEventWithPhotos,
  useSession,
  UseSessionT,
} from 'client/src/queries'

const UploadIdPhotoVld = z.object({
  id: z.string(),
  idPhoto: validImageFileVld,
})

type UploadIdPhotoT = z.infer<typeof UploadIdPhotoVld>

function PhotoUploadForm({user, technicianId}: {user:UseSessionT['user'], technicianId: string}) {
  const { t } = useTranslation()
  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos({specialEvents: 'technicianRegistration'})
  const {
    control, handleSubmit, reset: resetForm,
  } = useForm<UploadIdPhotoT>({
    resolver: zodResolver(UploadIdPhotoVld),
    defaultValues: {id: technicianId},
  })

  if (!user || !user.accessOps) {
    return <span>No id photo</span>
  }

  return (
  <>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} />
    <Form onSubmit={
      handleSubmit((data) => mutate.mutate({ event: data, type: 'technician' })) }
    >
      <Card className="border border-dark rounded p-2 mb-3">
      <Form.Group controlId="idPhoto">
        <Form.Label>
          {t('Upload id photo for technician')}
        </Form.Label>
        <PhotoInputField control={ control } name="idPhoto" />
      </Form.Group>
      <Button variant="primary" type="submit">
        { t('Submit') }
      </Button>
      </Card>
    </Form>
    </>
    )
}

function UserDetail() {
  const { userId } = useParams()
  const { isLoading, error, data } = useUser(userId)
  const { data: userData } = useSession()
  const user = userData?.user

  if (isLoading) {
    return (<div>Loading...</div>)
  }
  if (error) {
    return (<div>Error: {(error as Error).message}</div>)
  }

  const technician = data.user
  const rows = [
    {title: 'Name', value: technician.name},
    {title: 'Phone', value: technician.phone},
    {title: 'Company', value: technician.company},
    {title: 'Collection Partner', value: technician.collectionPartner || 'None'},
    {title: 'Certification', value: technician.certification || 'None'},
    {title: 'Registered At', value: technician.registeredAt ? <DateStr date={technician.registeredAt} /> : 'Not Registered'},
    {title: 'Validated At', value: technician.validatedAt ? <DateStr date={technician.validatedAt} /> : 'Not Validated'},
  ].map((row, index) => {
    return (<Row key={index}>
      <Col xs={12} sm={3}><b>{row.title}</b></Col>
      <Col xs="auto">{row.value}</Col>
    </Row>)
  })

  return (<>
    <h1>User Details</h1>
    <div>
      <Row>
        <Col xs={{span: 8, offset: 2}} lg={{span: 4, offset: 0}}>
        { technician.idPhoto ? <EventImage id={technician.idPhoto} label='Technician Id' /> : <PhotoUploadForm user={user} technicianId={technician.id}/> }
          </Col>
      </Row>
      {rows}
    </div>
    </>)
}

export default UserDetail
