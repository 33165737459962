import { useState } from 'react'
import type { UseEventDetailT } from 'client/src/queries'
import { useDeleteEvent } from 'client/src/queries'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { addAlert } from '../state/alerts'


function DeleteEvent({event} : {event: UseEventDetailT['event']}) {
  const mutate = useDeleteEvent()
  const [showWarning, setShowWarning] = useState(false);
  const handleDelete = () => {
      mutate.mutate(
        { event: event.event, type: event.type },
        {
          onSuccess: () => {
            addAlert({
              level: 'ok',
              message: 'Successfully deleted',
              autoClose: true,
           })},
           onError: (err) => {
             const errMsg = err instanceof Error ? err.message : String(err)
             setShowWarning(false)
             addAlert({
               level: 'error',
               message: `Deletion failed with error ${ errMsg }`,
               autoClose: true,
               })
           },
        }
      )
    }
  if (!event.event.deletedAt) {
    return (
      <div>
      { (mutate.isLoading) ? (
        'Loading...'
      ) : (
        <>
          <Button onClick={() => { setShowWarning(true) }}>
            Delete Event
          </Button>
          <Modal show={showWarning} onHide={() => setShowWarning(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure? This action cannot be undone. Usually, you actually want to invalidate this in the Operations screen. Please check with Louis if you're not sure.</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowWarning(false)}>
                Never mind
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Yes, delete this
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
    )
  }
}

export default DeleteEvent
