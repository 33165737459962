import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import { useValidateToken } from 'client/src/queries'
import type { ValidateTokenParams } from 'client/src/queries'

function ButtonLoadingSpinner({ loading }: { loading: boolean }) {
  if (loading) {
    return <Spinner size='sm' variant='secondary' animation='border' />
  } else {
    return null
  }
}

type OtpValidateP = {
  setValidated: (v: boolean) => void,
  validateParams: ValidateTokenParams
}
function PhoneOtpValidate({setValidated, validateParams}: OtpValidateP) {
  /*
   * form to enter the token
   * read `to` and `isExternalOtp` from validateParams
   * button to submit the token + other params
   * set validated to true if the token is valid
   * display success/error appropriately
  */
  const { t } = useTranslation()
  const { control, handleSubmit, setError, formState: {errors} } = useForm<Pick<ValidateTokenParams, 'token'>>({})
  const validateToken = useValidateToken()
  const onSubmit = (data: Pick<ValidateTokenParams, 'token'>) => {
    validateToken.mutate(
      { ...validateParams, ...data},
      { onSuccess: () => setValidated(true),
        onError: () => {
          setValidated(false)
          setError('token', { message: t('Invalid Code') })
        },
      }
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId='token'>
        <Form.Label>{t('Enter Code')}</Form.Label>
        <Form.Control
          name='token'
          type='text'
          autoComplete="one-time-code"
          placeholder={t('Code')}
          {...control.register('token', { required: true })}
          isInvalid={ 'token' in errors }
        />
        <Form.Control.Feedback type="invalid">
          { t(errors.token?.message || 'Error') }
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        variant="primary" type="submit" disabled={ validateToken.isLoading }
      >
        <ButtonLoadingSpinner loading={ validateToken.isLoading } />
        { t('Submit') }
      </Button>
    </Form>
  )
}

export type { OtpValidateP }
export default PhoneOtpValidate
