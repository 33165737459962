import { useEffect } from 'react'

import AlertBS from 'react-bootstrap/Alert';
import Stack from 'react-bootstrap/Stack'

import useAlertsState from '../state/alerts'
import type { Alert as AlertT, AlertsState} from '../state/alerts'

const AutoCloseTimeout = 5000  // 5 seconds

function alertVariant(level: AlertT['level']) {
  if (level === 'ok') return 'success'
  if (level === 'warn') return 'warning'
  if (level === 'error') return 'danger'
  return 'info'
}

function Alert({ alert, clear }: { alert: AlertT, clear: AlertsState['clearAlert'] }) {
  useEffect(() => {
    if (alert.autoClose) {
      const id = setTimeout(() => {
        clear(alert.id)
      }, AutoCloseTimeout)

      return () => clearTimeout(id)
    }
  }, [alert])

  return <AlertBS
    variant={ alertVariant(alert.level) }
    dismissible
    onClose={ () => clear(alert.id) }
    key={ alert.id }
  >
    { alert.message }
  </AlertBS>

}

function Alerts() {
  const alerts = useAlertsState()

  return (
    <Stack gap={1}>
      { alerts.alerts.map(alert => (
        <Alert alert={alert} key={alert.id} clear={alerts.clearAlert}/>
      ))}
    </Stack>
  )
}

export default Alerts
