import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {Whatsapp} from 'react-bootstrap-icons'


type WhatsappButtonProps = {
  buttonText: string,
  msg?: string,
}

function WhatsappButton(props: WhatsappButtonProps) {
  const supportWhatsappUrl = 'https://wa.me/6285171110816'

  return (
    <a
      className='btn btn-primary btn-lg btn-whatsapp container-fluid'

      href={ supportWhatsappUrl + (props.msg ? `?text=${props.msg}` : '') }
      target="_blank"
    >
      <Row>
        <Col xs={1}> <Whatsapp/> </Col>
        <Col>{props.buttonText}</Col>
      </Row>
    </a>
  );
}

export default WhatsappButton;
