import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useForm, Controller } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import { useSearchString } from '../lib/searchState'

import { useEquipmentState, useUpdateEventWithPhotos } from 'client/src/queries'

import { validImageFileVld, PhotoInputField } from './FormFields'
import CreateProgressModal from './CreateProgressModal'
import EquipmentPicker from './EquipmentPicker'

const CreateWeightVld = z.object({
  weightAt: z.date(),
  equipmentId: z.string().min(5, 'Cylinder code is required'),
  weight: z.number({
    required_error: 'Weight is required',
    invalid_type_error: 'Weight must be a number in kilograms',
  }).positive(),
  scaleId: z.string().min(5, 'Scale equipment code is required'),
  notes: z.string(),
  weightPhoto: validImageFileVld,
})

type CreateWeightT = z.infer<typeof CreateWeightVld>

function WeightForm() {
  const { t } = useTranslation()

  const [ equipmentId, _setEquipmentId ] = useSearchString('', 'eqId')

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos()

  const {
    register, control, handleSubmit, setValue, watch, setError, clearErrors, formState: { errors }, reset: resetForm,
  } = useForm<CreateWeightT>({
    resolver: zodResolver(CreateWeightVld),
  })

  const scaleId = watch('scaleId')
  const { data: scaleState } = useEquipmentState(scaleId)

  useEffect(() => {
    if (scaleState && !scaleState.equipment.seriesType.toLowerCase().includes('scale')) {
      setError('scaleId', { message: `Equipment ${scaleState.equipment.eqStickerCode} is not a scale` })
    }
  }, [scaleState, setError])

  useEffect(() => {
    if (equipmentId) {
      setValue('equipmentId', equipmentId, { shouldTouch: true })
    }
  }, [equipmentId, setValue])

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} />
    <Row><Col className="mx-auto" md="8" lg="6">
      <h2>{ t('Weight') }</h2>

      <Form onSubmit={ handleSubmit((data) => mutate.mutate({ event: data, type: 'weight' })) }>
        <Form.Group controlId="weightDate" className="mb-3">
          <Form.Label>{ t('Date the weighting was performed') }</Form.Label>
          <Form.Control
            type="date"
            max={ (new Date()).toISOString().split('T')[0] }
            {...register('weightAt', { valueAsDate: true })}
            isInvalid={ 'weightAt' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.weightAt?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="equipmentId">
            { t('Cylinder Code') }
          </Form.Label>
          <Controller
            control={ control }
            name="equipmentId"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => (<>
                <EquipmentPicker
                  types={['cylinder']}
                  id="equipmentId"
                  placeholder={ t('Select equipment code...') }
                  clearButton={ true }
                  selected={ value ? [value] : [] }
                  onChange={ (ids: string[]) => onChange(ids[0]) }
                  onBlur={ onBlur }
                  isInvalid={ invalid }
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>)
            }
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="scaleId">
            { t('Scale Code') }
          </Form.Label>
          <Controller
            control={ control }
            name="scaleId"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => (<>
                <EquipmentPicker
                  types={['scale']}
                  id="scaleId"
                  placeholder={ t('Select scale code...') }
                  clearButton={ true }
                  selected={ value ? [value] : [] }
                  onChange={ (ids: string[]) => { onChange(ids[0]); clearErrors('scaleId'); } }
                  onBlur={ onBlur }
                  isInvalid={ invalid }
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>)
            }
          />
        </Form.Group>

        <Form.Group controlId="weight" className="mb-3">
          <Form.Label>{ t('Weight (kilograms)') }</Form.Label>
          <Form.Control
            {...register('weight', { valueAsNumber: true }) }
            type="number"
            step="0.01"
            isInvalid={ 'weight' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.weight?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="notes" className="mb-3">
          <Form.Label>
            { t('Additional Note') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter notes') }
            {...register('notes')}
            isInvalid={ 'notes' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.notes?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="weightPhoto" className="mb-3">
          <Form.Label>{ t('Supporting Photo') }</Form.Label>
          <PhotoInputField control={ control } name="weightPhoto" />
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

export default WeightForm;
