import { useTranslation } from 'react-i18next'
import { omit, compact } from 'lodash-es'

// https://github.com/ericgio/react-bootstrap-typeahead/blob/HEAD/docs/API.md
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'

import { useEquipmentLocations, useUpdateLocation, UseEquipmentLocationsT } from 'client/src/queries'

type Location = UseEquipmentLocationsT['locations'][number]
type LocationPickerP = Omit<
  Parameters<typeof Typeahead>[0],
  'isLoading' | 'options' | 'labelKey' | 'selected' | 'onChange'
> & {
  selected: Array<Location['id']>,
  onChange: (locationIds: Array<Location['id']>) => void | Promise<void>,
}

function LocationPicker({allowNew = false, ...props}: {allowNew?: boolean} & LocationPickerP) {
  const { t } = useTranslation()
  const { isLoading, data } = useEquipmentLocations()
  const locations = data?.locations || []
  const { mutate } = useUpdateLocation()

  const taProps = omit(props, ['selected', 'onChange'])
  const selected = compact(props.selected.map(locId => locations.find(loc => loc.id === locId)))

  // customOption is a boolean field added by react-bootstrap-typeahead on a new value
  // https://github.com/ericgio/react-bootstrap-typeahead/blob/be5f198e2d13bcd479f610e2c2950b4f08b79772/src/core/Typeahead.tsx#L296
  // it **also** adds an 'id' field "new-id-1" to that value, which is why we need to update with only the `name` field.
  const onChange = async (selected: Array<Location & {customOption?: boolean}>) => {
    const newLocations = selected.filter(loc => loc.customOption)
    const existingLocations = selected.filter(loc => !loc.customOption)

    if (allowNew && newLocations.length > 0) {
      newLocations.map((newLocation: Partial<Location>) => {
        mutate(
          {update: {name: newLocation.name}},
          {onSuccess: async (data) => {
            await props.onChange([...existingLocations.map(loc => loc.id), data.location.id])},
          })})
    } else {
      await props.onChange(existingLocations.map(loc => loc.id));
    }
  }

  const labelKey: keyof Location = 'name'

  return (
    <Typeahead
      {...taProps}
      allowNew={allowNew}
      newSelectionPrefix={allowNew ? t('Add a new location: ') : undefined}
      isLoading={isLoading}
      options={ locations }
      labelKey={ labelKey }
      selected={ selected }
      // @ts-expect-error our option type is Location here
      onChange={onChange}
    />
  )
}

export default LocationPicker
