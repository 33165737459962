import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useForm, Controller } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import { useSearchString } from '../lib/searchState'

import { useUpdateEventWithPhotos } from 'client/src/queries'

import { validImageFileVld, PhotoInputField } from './FormFields'
import CreateProgressModal from './CreateProgressModal'
import EquipmentPicker from './EquipmentPicker'
import ExamplePhoto from './ExamplePhoto'

import borrowExample from '../assets/borrowExample.jpg'

const CreateReturnVld = z.object({
  returnerName: z.string().min(5),
  equipmentId: z.string().min(5, 'Equipment code is required'),
  returnAt: z.date().max(new Date(), {message: 'Return date must be in the past'}),
  returnPhoto: validImageFileVld,
})

type CreateReturnT = z.infer<typeof CreateReturnVld>

function ReturnForm() {
  const { t } = useTranslation()

  const [ equipmentId, _setEquipmentId ] = useSearchString('', 'eqId')

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos()

  const {
    register, control, handleSubmit, setValue, formState: { errors }, reset: resetForm,
  } = useForm<CreateReturnT>({
    resolver: zodResolver(CreateReturnVld),
  })

  useEffect(() => {
    if (equipmentId) {
      setValue('equipmentId', equipmentId, { shouldTouch: true })
    }
  }, [equipmentId, setValue])

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} />
    <Row><Col className="mx-auto" md="8" lg="6">
      <h2>{ t('Equipment Return') }</h2>

      <Form onSubmit={ handleSubmit((data) => mutate.mutate({ event: data, type: 'return' })) }>
        <Form.Group controlId="returnerName" className="mb-3">
          <Form.Label>
            { t('Name of the person returning the equipment.') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter name') }
            {...register('returnerName')}
            isInvalid={ 'returnerName' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.returnerName?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="returnAt" className="mb-3">
          <Form.Label>{ t('Date the equipment was returned') }</Form.Label>
          <Form.Control
            type="date"
            max={ (new Date()).toISOString().split('T')[0] }
            {...register('returnAt', { valueAsDate: true })}
            isInvalid={ 'returnAt' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.returnAt?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="equipmentId">
            { t('Equipment Code') }
          </Form.Label>
          <Controller
            control={ control }
            name="equipmentId"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => (<>
                < EquipmentPicker
                  id="equipmentId"
                  placeholder={ t('Select equipment code...') }
                  clearButton={ true }
                  selected={ value ? [value] : [] }
                  onChange={ (ids: string[]) => onChange(ids[0]) }
                  onBlur={ onBlur }
                  isInvalid={ invalid }
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>)
            }
          />
        </Form.Group>


        <Form.Group controlId="returnPhoto" className="mb-3">
          <Form.Label>{ t('Supporting Photo or Video') }</Form.Label>
          <ExamplePhoto src= {borrowExample} />
          <PhotoInputField control={ control } name="returnPhoto" />
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

export default ReturnForm;
