
import { Routes as RouterRoutes, Route } from 'react-router-dom';

import Linktree from './Linktree'
import Equipment from './Equipment'
import EquipmentTable from './EquipmentTable'
import NotFound from './NotFound'
import Login from './Login'
import Logout from './Logout'
import Sticker from './Sticker'
import TransferChains from './TransferChains'
import Ops from './Ops'
import Events from './Events'
import EventDetail from './EventDetail'
import ImageDetail from './ImageDetail'

import RecoveryForm from './RecoveryForm'
import QcForm from './QcForm'
import TransferForm from './TransferForm'
import UserForm from './UserForm'
import VacuumForm from './VacuumForm'
import WeightForm from './WeightForm'
import BorrowFormQr from './BorrowFormQr'
import BorrowForm from './BorrowForm'
import ReturnForm from './ReturnForm'
import MoveForm from './MoveForm'
import NewEquipmentForm from './NewEquipmentForm'
import GasIdentForm from './GasIdentForm'
import LocationForm from './LocationForm'
import UserDetail from './UserDetail'

import ErrorLog from './ErrorLog'

import RequireLogin from './RequireLogin';

function Routes() {
  return (
    <RouterRoutes>
      <Route path="/">
        <Route index element={<Linktree />} />

        <Route path="equipment" >
          <Route index element={<RequireLogin opsOnly={true} element={EquipmentTable} />} />
          <Route path=":stickerCode" element={<RequireLogin opsOnly={true} element={Equipment} />} />
        </Route>

        <Route path="gas" >
          <Route index element={<RequireLogin opsOnly={true} element={TransferChains} />} />
        </Route>

        <Route path="ops">
          <Route index element={<RequireLogin opsOnly={true} element={Ops} />} />
        </Route>

        <Route path="events">
          <Route index element={<RequireLogin opsOnly={true} element={Events} />} />
          <Route path=":eventId" element={<RequireLogin opsOnly={true} element={EventDetail} />} />
        </Route>

        <Route path="images">
          <Route path=":imageId" element={<RequireLogin opsOnly={true} element={ImageDetail} />} />
        </Route>

        <Route path="forms">
          <Route path="recovery" element={<RequireLogin element={RecoveryForm} />} />
          <Route path="transfer" element={<RequireLogin element={TransferForm} />} />
          <Route path="qc" element={<RequireLogin element={QcForm} />} />
          <Route path="vacuum" element={<RequireLogin element={VacuumForm} />} />
          <Route path="user" element={<UserForm />} />
          <Route path="weight" element={<RequireLogin element={WeightForm} />} />
          <Route path="borrow" element={<RequireLogin fieldOnly={true} element={BorrowFormQr} />} />
          <Route path="borrowBackfill" element={<RequireLogin opsOnly={true} element={BorrowForm} />} />
          <Route path="return" element={<RequireLogin element={ReturnForm} />} />
          <Route path="equipment" element={<RequireLogin element={NewEquipmentForm} />} />
          <Route path="move" element={<RequireLogin element={MoveForm} />} />
          <Route path="ident" element={<RequireLogin element={GasIdentForm} />} />
          <Route path="location" element={<RequireLogin element={LocationForm} />} />
        </Route>

        <Route path="user/:userId" element={<RequireLogin opsOnly={true} element={UserDetail} />} />


        <Route path="errorLog" element={<RequireLogin opsOnly={true} element={ErrorLog} />} />

        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />

        <Route path="stickers/:stickerUUID" element={<Sticker />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </RouterRoutes>
  )
}

export default Routes
