import { omit, compact } from 'lodash-es'

// https://github.com/ericgio/react-bootstrap-typeahead/blob/HEAD/docs/API.md
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'

import { useStickers, UseStickersT } from 'client/src/queries'

type Sticker = UseStickersT['stickers'][number]
type StickerPickerP = Omit<
  Parameters<typeof Typeahead>[0],
  'isLoading' | 'options' | 'labelKey' | 'selected' | 'onChange'
> & {
  unusedOnly: boolean,
  selected: Array<Sticker['code']>,
  onChange: (stickerCodes: Array<Sticker['code']>) => void | Promise<void>
}

function StickerPicker(props: StickerPickerP) {
  const { isLoading: stickerLoading, data: stickerData } = useStickers(props.unusedOnly)
  const stickers = stickerData?.stickers || []

  const taProps = omit(props, ['selected', 'onChange'])
  const selected = compact(props.selected.map(stCode => stickers.find(st => st.code === stCode)))
  const onChange = props.onChange

  return (
    <Typeahead
      {...taProps}
      isLoading={stickerLoading}
      options={ stickers }
      labelKey={ 'code' }
      selected={ selected }
      // @ts-expect-error our option type is equipment here
      onChange={ (selected: Sticker[]) => onChange(selected.map(st => st.code)) }
    />
  )
}

export default StickerPicker
