import { ChangeEvent } from 'react'

import { useTranslation } from 'react-i18next';

import { z } from 'zod'

import { Controller } from 'react-hook-form'
import type { Control } from 'react-hook-form'

import Form from 'react-bootstrap/Form'

const validImageTypes = [
  'image/png',
  'image/jpeg',
  'application/pdf',
]
const MAX_UPLOAD_SIZE = 25 * 1024 * 1024 // 25MB

const validImageFileVld = z.instanceof(File, {message:'Photo is required'}).superRefine(
  (file, ctx) => {
    if (!validImageTypes.includes(file.type)) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: `Invalid file type ${file.type}` })
    } else if (file.size > MAX_UPLOAD_SIZE) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'File is too large, max size is 25MB' })
    }
  }
)

const extractFile = (evt: ChangeEvent<HTMLInputElement>) => (
  evt.target.files?.length ? evt.target.files[0] : null
)

function PhotoInputField({ control, name }: { control: Control, name: string }) {
  const { t } = useTranslation()

  return (
    <Controller
      control={ control }
      name={ name }
      render={
        ({ field: { onChange, onBlur, ref }, fieldState: { invalid, error } }) => (<>
          <Form.Control
            type="file"
            isInvalid={ invalid }
            onBlur={ onBlur }
            ref={ ref }
            onChange={ (evt: ChangeEvent<HTMLInputElement>) => onChange(extractFile(evt)) }
          />
          <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
            { t(error?.message || 'Error') }
          </div>
        </>)
      }
    />
  )
}

export {
  PhotoInputField,
  validImageFileVld,
}
