import { ReactNode } from 'react'

import { create } from 'zustand'

type Alert = {
  id: string,
  level: 'ok' | 'warn' | 'error',
  message: ReactNode,
  autoClose?: true,
}

type AlertsState = {
  alerts: Array<Alert>,
  addAlert: (alert: Omit<Alert, 'id'>, fade?: true) => string,
  clearAlert: (id: string) => void,
}

const useAlertsState = create<AlertsState>()((set, get) => ({
  alerts: [],

  addAlert: (alert) => {
    let id: string
    do {
      id = Math.random().toString(16).substr(2, 8);
    } while (get().alerts.map(a => a.id).includes(id))

    const newAlert = {...alert, id}

    set({ alerts: [...get().alerts, newAlert] })
    return id
  },

  clearAlert: (id) => {
    set({ alerts: get().alerts.filter(a => a.id !== id) })
  },

}))

// convenient wrapper function
const addAlert: AlertsState['addAlert'] = (...p) => useAlertsState.getState().addAlert(...p)

export default useAlertsState
export {
  addAlert,
}
export type {
  Alert,
  AlertsState,
}
