import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { isValidPhoneNumber, Country } from 'react-phone-number-input'
import type { RequestTokenParams } from 'client/src/queries'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import { useRequestToken } from 'client/src/queries'
import { addAlert } from '../state/alerts'

function ButtonLoadingSpinner({ loading }: { loading: boolean }) {
  if (loading) {
    return <Spinner size='sm' variant='secondary' animation='border' />
  } else {
    return null
  }
}

type Method = RequestTokenParams['method']
type PhoneOtpRequestT = { phone: string, method: Method, isRequested?: boolean }
type OtpRequestP = {
  phoneOtpRequest: Partial<PhoneOtpRequestT>,
  setPhoneOtpRequest: (request: Partial<PhoneOtpRequestT>) => void,
}
function PhoneOtpRequest({phoneOtpRequest, setPhoneOtpRequest }: OtpRequestP) {
  /*
   * asks for a phone number
   * has a button to send a one-time code
   * sends the code to the phone number
   * sets the phone number and send method
   */
  const { t } = useTranslation()
  const { control, watch, handleSubmit, register, formState: { errors } } = useForm<Pick<PhoneOtpRequestT, 'phone'> & {agreement: boolean}>({})
  const phone = watch('phone')
  const [method, setSendMethod] = useState<Method>('otp')
  const { requested, requestToken } = useRequestToken()
  const [ waitTime, setWaitTime ] = useState<number>(0)

  const agreement = watch('agreement')
  useEffect(() => {
    if (phone) {
      setPhoneOtpRequest({...phoneOtpRequest, phone, method: method })
    }
  }, [phone])
  useEffect(() => {
    setPhoneOtpRequest({...phoneOtpRequest, isRequested: requested })
    if (requested) {
      setWaitTime(60)
    }
  }, [requested])

  // after a request, disable the button for 60 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setWaitTime(w => Math.max(w - 1, 0))
    }, 1000)
    return () => clearInterval(intervalId)
  }, )


  const sendMethodByCountry: Partial<Record<Country, Method>> = {
    'ID': 'otp',
    'US': 'phone',
  }

  const onSubmit = async (data: Pick<PhoneOtpRequestT, 'phone'>) => {
    setWaitTime(60)
    await requestToken.mutateAsync({
      identifier: data.phone,
      method: method,
    },
    {
      onError: (e) => {
        const error = e as Error
        addAlert({level: 'error', message: t(`Error: ${error.message}`)})
      },
    })
  }


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="formPhone">
        <Form.Label>{ t('Phone Number') }</Form.Label>
        { phone ?
          <>
            <div>
              {t('We\'ll send a one-time code to')}: <mark>{phone}</mark>.
            </div>
          </> :
          <></>
        }
        <PhoneInputWithCountry
          name='phone'
          control={control}
          onCountryChange={(country) => {
            setSendMethod(sendMethodByCountry[country] as Method)
          }}
          rules={{
            required: t('Phone number is required'),
            validate: (v: string) => isValidPhoneNumber(v) || t('Invalid phone number'),
          }}
          placeholder={t('Enter phone number')}
          error={ errors.phone && errors.phone.message }
          defaultCountry='ID'
          countries={['ID', 'US']}
          addInternationalOption={false}
          className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
        />

        <Form.Control.Feedback type="invalid">
          { t(errors.phone?.message || 'Error') }
        </Form.Control.Feedback>

        { waitTime > 0 ?
          <><Form.Text>
            { t('You can request another code in seconds seconds', {count: waitTime}) }
          </Form.Text><br></br></>
          : <></>
        }
        <Form.Text className="text-muted">
          { t('We\'ll never share your phone number with anyone else.') }
        </Form.Text>
      </Form.Group>

      {method === 'phone' ? <>
      <Form.Group controlId="agreement" className="mb-3">
        <Form.Label>I agree to receive SMS from Recoolit and understand that additional charges may apply.</Form.Label>
        <Form.Check
          {...register('agreement')}
          type="checkbox"
          id="agreement"
          label={t('Yes, I agree')}
          feedbackType='invalid'
          feedback={ t(errors.agreement?.message || 'Error') }
          isInvalid={'agreement' in errors}
        >
        </Form.Check>
      </Form.Group></>
      : <></>}
      <Button
        variant="primary" type="submit" disabled={ (requestToken.isLoading || waitTime > 0) || (method === 'phone' && !agreement) }
      >
        <ButtonLoadingSpinner loading={ requestToken.isLoading } />
        { t('Submit') }
      </Button>
    </Form>
  )

}

export default PhoneOtpRequest
export type { PhoneOtpRequestT }
