import { useEffect } from 'react'
import type { ComponentType } from 'react'

import { Navigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import Spinner from 'react-bootstrap/Spinner'

import { useSession } from 'client/src/queries'
import { addAlert } from '../state/alerts'

import NotFound from './NotFound'

type RequireLoginP = {
  element: ComponentType,
  opsOnly?: boolean,
  fieldOnly?: boolean,
}

function RequireLogin({ element: Component, opsOnly, fieldOnly, ...rest }: RequireLoginP) {
  const { t } = useTranslation()
  const { isLoading, isError, data } = useSession()
  const location = useLocation()

  const shouldRedirect = !isLoading && !isError && data && !data.user

  // add an alert as a side effect of redirecting to login page
  useEffect(() => {
    if (shouldRedirect) {
      addAlert({ level: 'warn', message: t('Please log in to use that page'), autoClose: true })
    }
  }, [shouldRedirect])

  const hasUser = !isLoading && !isError && data
  const user = hasUser ? data.user : null
  const forbiddenOps = (!user?.accessOps && opsOnly)
  const forbiddenField = (!user?.accessField && fieldOnly)
  const isForbidden = hasUser && (forbiddenOps || forbiddenField)

  if (shouldRedirect) {
    const destination = location.search ? (
      location.pathname + '?' + location.search)
      : location.pathname

    const params = new URLSearchParams()
    params.set('destination', destination)

    return <Navigate to={ `/login?${params.toString()}` } />
  }

  if (isForbidden) {
    return <NotFound />
  }

  if (isLoading) {
    return <Spinner variant='primary' animation='border' />
  }

  return <Component {...rest}/>
}

export default RequireLogin

