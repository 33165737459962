import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { isEqual } from 'lodash-es'

// based on example here, modified for react-router
// https://pierrehedkvist.com/posts/react-state-url

function useSearchState<T>(
  paramName: string,
  serialize?: (val: T) => string | null,
  deserialize?: (val: string | null) => T,
): [T, (val: T) => void] {
  const [ search, setSearch ] = useSearchParams()
  const existingValue = search.get(paramName)

  const [state, setState] = useState<T>(deserialize(existingValue))

  // Updates state when value changes in search params
  useEffect(() => {
    setState(deserialize(existingValue))
  }, [existingValue])

  const onChange = (newVal: T) => {
    setState(newVal);

    const newSearchVal = serialize(newVal)
    if (newSearchVal === null) {
      search.delete(paramName)
    } else {
      search.set(paramName, newSearchVal)
    }

    setSearch(search)
  }

  return [state, onChange];
}

function curFromSearchDefaults(search: URLSearchParams, defaultVals: Record<string, string | string[]>) {
  const cur = {...defaultVals}
  for (const k of Object.keys(defaultVals)) {
    if (search.has(k)) {
      if (Array.isArray(defaultVals[k])) {
        cur[k] = search.get(k).split(',')
      } else {
        cur[k] = search.get(k)
      }
    }
  }
  return cur
}

function useSearchObject<T extends Record<string, string | string[]>>(defaultVals: T) {
  const [ search, setSearch ] = useSearchParams()
  const [ state, setState ] = useState<T>(() => curFromSearchDefaults(search, defaultVals) as T)

  useEffect(() => {
    setState({...state, ...curFromSearchDefaults(search, defaultVals)})
  }, [search])

  const onChange = (updates: Partial<T>) => {
    const newState = {...state, ...updates} as T
    setState(newState)

    for (const k of Object.keys(newState)) {
      if (isEqual(newState[k], defaultVals[k])) {
        search.delete(k)
      } else {
        if (Array.isArray(newState[k])) search.set(k, (newState[k] as string[]).join(','));
        else search.set(k, newState[k] as string)
      }
    }

    setSearch(search)
  }

  return [state, onChange] as const
}

function useSearchString<T = string>(initialValue: T, paramName: string) {
  const serialize = (stateVal: T): string | null => {
    if (stateVal === initialValue) return null
    return stateVal as string
  }

  const deserialize = (searchVal: string | null) => {
    if (searchVal === null) return initialValue
    return searchVal as T
  }

  return useSearchState<T>(paramName, serialize, deserialize)
}

function useSearchArray(initialValue: string[], paramName: string) {
  const serialize = (stateVal: string[]) => {
    if (stateVal.length) return stateVal.join(',')
    return null
  }

  const deserialize = (searchVal: string | null) => {
    if (searchVal === null) return initialValue
    if (!searchVal) return []
    return searchVal.split(',')
  }

  return useSearchState<string[]>(paramName, serialize, deserialize)
}

function useSearchFlag(paramName: string) {
  const serialize = (stateVal: boolean) => stateVal ? '1' : null
  const deserialize = (searchVal: string | null) => !!searchVal
  return useSearchState<boolean>(paramName, serialize, deserialize)
}

export {
  useSearchString,
  useSearchArray,
  useSearchFlag,
  useSearchObject,
}
