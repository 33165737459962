import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Stack from 'react-bootstrap/Stack'

import WhatsappButton from './WhatsappButton'

import { useEquipmentBySticker } from 'client/src/queries'
import EquipmentDetails from './EquipmentDetails';
import EquipmentActions from './EquipmentActions';

function Sticker() {
  const { t } = useTranslation()
  const { stickerUUID } = useParams()

  const { isLoading, error, data } = useEquipmentBySticker(stickerUUID)

  if (isLoading) {
    return (<Stack direction='horizontal' gap={1}>
      <Spinner variant='primary' animation='border' />
      <h3>{ t('Loading equipment info...') }</h3>
    </Stack>)
  }

  if (error) {
    return (<Stack direction='vertical' gap={1}>
      <h3>{ t('Loading equipment info failed!') }</h3>

      <pre className="p-2">
        { (error as Error).message }
      </pre>

      <WhatsappButton
        buttonText= {t('Chat us on Whatsapp')}
        msg={ t('Problem on sticker page id', { id: stickerUUID }) }
      />

    </Stack>)
  }

  const equipment = data.equipment
  const equipmentState = data.equipmentState

  return (
    <div>
      <Row>
        <Col xs={12} md={8} lg={7}>
          <EquipmentDetails equipment={equipment} equipmentState={equipmentState} />
        </Col>

        <Col xs={12} md={4} lg={5}>
          <EquipmentActions equipment={equipment} isBorrowed={!!equipmentState.borrowAt} />
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
        <Row xs={12} md={4}><em>{t('See something wrong?')}</em></Row>
          <Row>
          <WhatsappButton
            buttonText= {t('Chat us on Whatsapp')}
            msg={ t('Problem with sticker code', { code: data?.equipment.stickerCode || '' }) } />
      </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Sticker;
