import { ReactNode } from 'react'
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import type { UseEquipmentByStickerT } from 'client/src/queries'
import DateStr from './DateStr'

type DetailRowP = {
  title: string,
  index: number,
  value: ReactNode | { value: ReactNode, className: string },
}

function DetailRow({ title, index, value}: DetailRowP) {
  let className = classNames('bg-opacity-10', {
    'bg-dark': index % 2 === 0,
  })
  let valueRendered = value

  if (value instanceof Object && 'value' in value) {
    className = classNames(className, value.className)
    valueRendered = value.value
  }

  return (
    <Row className={ className }>
      <Col xs={12} sm={4}><b>{ title }</b></Col>
      <Col>{ valueRendered as ReactNode }</Col>
    </Row>
  )
}

type EquipmentDetailsP = {
  equipment: UseEquipmentByStickerT['equipment'],
  equipmentState: UseEquipmentByStickerT['equipmentState'],
}

function EquipmentDetails({ equipment, equipmentState}: EquipmentDetailsP) {
  const { t } = useTranslation()

  const borrowAt = equipmentState.borrowAt ? (<DateStr date={equipmentState.borrowAt}/>) : 'not borrowed'
  let detailRows: Record<DetailRowP['title'], DetailRowP['value']> = {
    'Sticker UUID': (<Link to={`/stickers/${equipment.stickerId}`}>{equipment.stickerId}</Link>),
    'Sticker Code': (<Link to={`/equipment/${equipment.stickerCode}`}>{equipment.stickerCode}</Link>),
    'Equipment ID': equipment.id,
    'Equipment Type': equipment.seriesType,
    'Owner': equipment.owner,
    'Capacity': equipment.seriesCapacity,
    'Last Seen': <DateStr date={equipmentState.ts} />,
    'Latest Location': equipmentState.locationName,
    'Latest QC Result': {
      value: equipmentState.condition,
      className: classNames('bg-opacity-25', {
        'bg-success': equipmentState.condition === 'pass',
        'bg-warning': equipmentState.condition === 'caution',
        'bg-danger': equipmentState.condition === 'fail',
      }),
    },
    'Latest Steward': equipmentState.steward,
    'Borrowed since': borrowAt,
  }

  if (equipmentState.cylinderState) {
    detailRows = {...detailRows, ...{
      'Tare Weight': equipment.tareWeight?.toFixed(2),
      'Net Weight': equipmentState.cylinderState.gasQuantity.toFixed(2),
      'Gas Type': equipmentState.cylinderState.gasType,
      'Freshly Vacuumed': equipmentState.cylinderState.isFreshlyVacuumed.toString(),
    }}
  }

  return (<>
    <h1>{ t('Equipment Details') }</h1>

    { Object.entries(detailRows).map(([title, value], idx) => (
      <DetailRow title={t(title)} value={value} key={idx} index={idx} />
    )) }
  </>)
}

export default EquipmentDetails
