import '../App.scss'

import { BrowserRouter } from 'react-router-dom';
import { queryClient, QueryClientProvider } from 'client'

import ThemeProvider from 'react-bootstrap/ThemeProvider'
import Container from 'react-bootstrap/Container'

import Alerts from '../components/Alerts'
import Navbar from '../components/Navbar'
import Routes from '../components/Routes'
import Errors from '../components/Errors'

function App() {
  return (
    <QueryClientProvider client={ queryClient }>
      <BrowserRouter>
        <ThemeProvider>
          <Navbar />
          <Container fluid="md" className="mobile-background ps-2">
            <Errors>
              <Alerts />
              <Routes />
            </Errors>
          </Container>
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
