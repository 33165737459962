import { useEffect, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'

import { useSession, useLogout } from 'client/src/queries'

function Logout() {
  const { t } = useTranslation()

  const logout = useLogout()

  // are we even logged in?
  const { data } = useSession()
  const currentUser = data?.user

  // log out when opening this page
  useEffect(() => {
    void logout.mutateAsync()
    return logout.reset
  }, [])

  let text: ReactNode
  if (!currentUser) {
    text = <Card.Text> { t('You are logged out') } </Card.Text>
  } else if (logout.isLoading) {
    text = <Card.Text>
      <Spinner variant='primary' animation='border' />
      { t('You are being logged out of the app') }
    </Card.Text>
  } else {
    text = <>
      <Card.Text>
        { t('Logout attempt failed') }
      </Card.Text>

      <Button variant="primary" onClick={() => void logout.mutateAsync()}>
        { t('Try again') }
      </Button>
    </>
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          { t('Log Out') }
        </Card.Title>
        { text }
      </Card.Body>
    </Card>
  )
}

export default Logout
