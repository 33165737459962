import { useEffect, useRef } from 'react'
import { QrCode, Ecc } from '../lib/qr'


const QrEncoder = ({ text }: {text: string}) => {
  /*
     mainly taken from:
     https://github.com/greggman/QR-Code-generator/blob/66ad41daf0c37c84b357c1218bbdcb82abb89976/typescript-javascript/use-as-es6-module.html
   */


  // higher error correction level is more resilient to sticker damage (good) but longer to scan (bad)
  // since we are showing this digitally, just set to LOW
  const errorLevel = Ecc.LOW
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const qr = QrCode.encodeText(text, errorLevel);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const scale = 10;

    canvas.width = qr.size * scale;
    canvas.height = qr.size * scale;
    ctx.scale(scale, scale);

    for (let y = 0; y < qr.size; y++) {
      for (let x = 0; x < qr.size; x++) {
        if (qr.getModule(x, y)) {
          ctx.fillRect(x, y, 1, 1);
        }
      }
    }
  }, [text]);

  return <canvas ref={canvasRef} />;
};


export default QrEncoder
