import { PropsWithChildren } from 'react'

import { ErrorBoundary } from '@sentry/react'

import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next';

import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Stack from 'react-bootstrap/Stack'

type ErrorFallbackP = {
  // why is error unknown?
  // this propagates from the fact that anything can be used with a `throw` statement
  // React <20 mistakenly uses Error type in componentDidCatch
  // https://github.com/getsentry/sentry-javascript/discussions/12571#discussioncomment-9830731
  error: unknown,
  componentStack: string,
  reset: () => void,
}
function ErrorFallback({ error, componentStack, reset }: ErrorFallbackP) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Card className="mx-auto mt-3">
      <Card.Header>{ t('Something Went Wrong') }</Card.Header>
      <Card.Body>
        <Card.Title>{ t('We encountered an unexpected error') }:</Card.Title>
        <pre className="bg-light border border-danger p-2">{ error instanceof Error ? error.message : 'Unknown Error' }</pre>

        <Accordion>
          <Accordion.Item eventKey="stack">
            <Accordion.Header>{ t('Error Stack') }</Accordion.Header>
            <Accordion.Body>
              <pre className="bg-light border border-danger p-2">{ componentStack }</pre>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card.Body>

      <Stack direction="horizontal" className="px-3 pb-3" gap={ 2 }>
        <Button variant="warning" className="ms-auto" onClick={ reset }>
          { t('Try again') }
        </Button>
        <Button variant="success" onClick={ () => {
          navigate('/')
          reset()
        } }>
          { t('Go To Home Page') }
        </Button>
      </Stack>
    </Card>
  )
}

function Errors(props: PropsWithChildren) {
  return (
    <ErrorBoundary
      fallback={ ({ error, componentStack, resetError}) => (
        <ErrorFallback error={error} componentStack={componentStack} reset={resetError} />
      )}
    >
      { props.children }
    </ErrorBoundary>
  )
}

export default Errors
