import { ReactNode, ComponentProps } from 'react'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Spinner from 'react-bootstrap/Spinner'

import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Brand from '../assets/brand-light.svg'

import LangPicker from './LangPicker'

import { useSession } from 'client/src/queries'

type NavLinkProps = ComponentProps<typeof Nav.Link> & ComponentProps<typeof Link>
function NavLink(props: NavLinkProps) {
  return (
    <Nav.Link as={Link} eventKey={props.eventKey} {...props} />
  )
}

function RecoolitNavbar() {
  const { t } = useTranslation()

  const { isLoading, data } = useSession()
  const currentUser = data?.user
  const accessOps = currentUser?.accessOps

  // activeKey is set based on the path,
  // so new NavLink elements must set eventKey to the dest path
  const location = useLocation()
  const path = location.pathname.slice(1)

  const curText = isLoading ? (
    <Spinner animation='border' variant='secondary' size='sm' /> ) : (
      <Navbar.Text>{ currentUser?.name || '' }</Navbar.Text>)

  let loginLink: ReactNode
  if (currentUser) {
    loginLink = (
      <NavLink to="/logout" className="nav-link" eventKey="logout" key="logout">
        { t('Log Out') }
      </NavLink>
    )
  } else {
    loginLink = (
      <NavLink to="/login" className="nav-link" eventKey="login" key="login">
        { t('Log in') }
      </NavLink>
    )
  }

  const adminLinks: ReactNode[] = []
  if (accessOps) {
    adminLinks.push(
      <NavLink to="/equipment" className="nav-link" eventKey="equipment" key="equipment">{ t('Equipment') }</NavLink>,
      <NavLink to="/gas" className="nav-link" eventKey="gas" key="gas">{ t('Gas') }</NavLink>,
      <NavLink to="/ops" className="nav-link" eventKey="ops" key="ops">{ t('Operations') }</NavLink>,
      <NavLink to="/events" className="nav-link" eventKey="events" key="events">{ t('Events') }</NavLink>,
      <NavLink to="/errorLog" className="nav-link" eventKey="errors" key="errors">{ t('Errors') }</NavLink>,
    )
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="rcdblue" sticky="top" variant="dark" className="py-3 px-4 py-lg-4 px-lg-5">
      <Navbar.Brand data-testid="navbarBrand" className="navbar-brand" as={ NavLink } eventKey="home" key="home" to="/">
        <img src={Brand} alt="Recoolit Logo" className="d-inline-block align-top" />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="main-navbar" />

      <Navbar.Collapse id="main-navbar">
        <Nav className="me-auto" activeKey={path}>
          {adminLinks}
        </Nav>
        <hr className='navbar-divider'/>

        <Nav activeKey={path}>
          { curText }
          { loginLink }

          <LangPicker />
        </Nav>

      </Navbar.Collapse>
    </Navbar>
  );
}

export default RecoolitNavbar;
